















import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { ChartsStoreModule } from "@/store/modules/charts";
import { Account } from "@/store/modules/accounts";

@Component({
  components: {},
})
export default class AssetsDistributionWidget extends Vue {
  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;

  chartOptionsPie = {
    chart: {
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    theme: {},

    stroke: {
      curve: "smooth",
      width: 2,
    },
    legend: {
      // position: "top",
      // markers: {
      //   radius: 2,
      // },
      // show: false,
    },
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    plotOptions: {
      pie: {
        offsetX: 0,
        offsetY: 0,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: "50%",
        },
      },
    },
  };

  seriesPie: any = [];

  mounted() {
    let chart: any = this.$refs["pieChart"];
    this.chartOptionsPie.labels = chart.updateOptions({
      labels: this.account.portfolio!.map((coin) => coin.asset),
    });
    this.seriesPie = this.account.portfolio!.map((coin) => +coin.percentage);
  }
}

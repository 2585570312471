import {
  Module,
  VuexModule,
  getModule,
  Mutation,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import axios from "axios";

@Module({ dynamic: true, store: store, name: "bots" })
class Bots extends VuexModule {
  bots = [];

  @Action({ rawError: true })
  getBots() {
    return axios
      .get(`/api/v1/bots/list`)
      .then((resp) => {
        console.log(resp)
        this.setBots(resp.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Mutation
  setBots(values: any) {
    this.bots = values;
  }
}

export const BotsStoreModule = getModule(Bots);























































import { Component, Vue } from "vue-property-decorator";
import { SignalsStoreModule } from "@/store/modules/signals";

@Component({
  components: {},
})
export default class Signals extends Vue {
  options = [
    {
      label: "Predictum",
      key: "predictum",
    },
  ];

  value = {
    label: "Predictum",
    key: "predictum",
  };

  predictumHeaders = [
    // { text: "Exchange", value: "exchange" },
    { text: "Symbol", value: "symbol" },
    { text: "Short Term Accuracy", value: "short" },
    { text: "Long Term Accuracy", value: "long" },
    { text: "Direction", value: "direction" },
    { text: "Price", value: "price" },
    { text: "Stop Loss", value: "stop_loss" },
    { text: "Date", value: "date" },
  ];

  get predictumSignals() {
    return SignalsStoreModule.predictumSignals;
  }

  mounted() {
    SignalsStoreModule.getPredictumSignals();
  }
}

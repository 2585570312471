






































import { Component, Prop, Vue } from "vue-property-decorator";
import { Account } from "@/store/modules/accounts";
import { AuthStoreModule } from "@/store/modules/auth";
import moment from "moment";
import {FuturesAccount} from "@/store/modules/futuresAccounts";

@Component({
  components: {},
})
export default class FuturesAccountRow extends Vue {
  @Prop({
    default: () => {
      return new FuturesAccount({});
    },
  })
  account!: FuturesAccount;

  get isOwn() {
    return this.account.user?.id === AuthStoreModule.user.id;
  }

  get balancingNotOccured() {
    return (
      moment().diff(moment(this.account.walletStatus?.updatedAt), "minutes") > 2
    );
  }

  timeAgo(date: string) {
    return moment(date).fromNow();
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"broadcast-view default-view"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"header-title"},[_vm._v("Broadcast")]),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","left":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"action-menu clickable ml-auto mr-4",attrs:{"elevation":"0","color":"","text":""}},on),[_c('span',{staticClass:"fw-600 name mr-1"},[_vm._v("Signal Type:")]),_c('span',[_vm._v(_vm._s(_vm.value.label))])])]}}])},[_c('v-list',{staticClass:"action-menu-list"},_vm._l((_vm.options),function(option){return _c('v-list-item',{key:option.label,attrs:{"link":""},on:{"click":function($event){_vm.value = option}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(option.label)+" ")])],1)}),1)],1),_c('v-btn',{staticClass:"default-size small-icon",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showEmitBroadcastModal = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Emit Broadcast")],1)],1),_c('div',{staticClass:"content"},[(_vm.value.key === 'entry')?_c('v-data-table',{staticClass:"default-table-widget",attrs:{"headers":_vm.entryHeaders,"items":_vm.entrySignals,"items-per-page":10,"sort-desc":true,"sort-by":'createdAt'},scopedSlots:_vm._u([{key:"item.caller",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.caller && item.caller.name ? item.caller.name : "Unknown")+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,"DD MMMM YYYY - HH:mm"))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"DD MMMM YYYY - HH:mm"))+" ")])]}}],null,true)}):_c('v-data-table',{staticClass:"elevation-1 default-table-style",attrs:{"headers":_vm.updateHeaders,"items":_vm.updateSignals,"items-per-page":10,"sort-desc":true,"sort-by":'createdAt'},scopedSlots:_vm._u([{key:"item.caller",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.caller && item.caller.name ? item.caller.name : "Unknown")+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,"DD MMMM YYYY - HH:mm"))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"DD MMMM YYYY - HH:mm"))+" ")])]}}],null,true)})],1),_c('EmitBroadcastModal',{attrs:{"dialog":_vm.showEmitBroadcastModal},on:{"update:dialog":function($event){_vm.showEmitBroadcastModal = $event},"emit":_vm.emitBroadcast}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }








































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { MenuStoreModule } from "@/store/modules/menu";
import { AuthStoreModule } from "@/store/modules/auth";
import { AccountsStoreModule } from "@/store/modules/accounts";
import ProfileModal from "@/components/modals/ProfileModal.vue";
import moment from "moment";
import { SharedStoreModule } from "@/store/modules/shared";

@Component({
  components: {
    ProfileModal,
  },
})
export default class Navbar extends Vue {
  showProfileModal = false;

  get notBalancedAccounts() {
    if (this.accounts.own || this.accounts.managed)
      return {
        managed: this.accounts.managed.filter((account) => {
          return (
            moment().diff(moment(account.lastBalancing?.createdAt), "minutes") /
              60 >
            account.settings?.balanceInterval!
          );
        }),
        own: this.accounts.own.filter((account) => {
          return (
            moment().diff(moment(account.lastBalancing?.createdAt), "minutes") /
              60 >
            account.settings?.balanceInterval!
          );
        }),
      };
    return {
      managed: [],
      own: [],
    };
  }

  get isThemeLight() {
    return SharedStoreModule.isThemeLight;
  }

  set isThemeLight(newValue: boolean) {
    localStorage.setItem("theme", newValue ? "light" : "dark");
    this.$vuetify.theme.dark = !newValue;
  }

  get accounts() {
    return AccountsStoreModule.accounts;
  }

  get currentUser() {
    return AuthStoreModule.user;
  }

  get drawer() {
    return MenuStoreModule.drawer;
  }

  set drawer(value: boolean) {
    MenuStoreModule.setDrawer(value);
  }

  get accountsSearchTerm() {
    return AccountsStoreModule.accountsSearchTerm;
  }

  set accountsSearchTerm(value: string) {
    AccountsStoreModule.setAccountSearchTerm(value);
  }

  get accountViewSearchTerm() {
    return AccountsStoreModule.accountsSearchTerm;
  }

  set accountViewSearchTerm(value: string) {
    AccountsStoreModule.setAccountSearchTerm(value);
  }

  get isAccountsView() {
    return (
      this.$route.name === "Accounts" || this.$route.name === "FuturesAccounts"
    );
  }

  get isAccountView() {
    return (
      this.$route.name === "AccountView" ||
      this.$route.name === "FuturesAccountView"
    );
  }

  toggleProfileModal(value: boolean) {
    this.showProfileModal = value;
  }

  logout() {
    AuthStoreModule.logout();
    this.$router.push("/login");
  }

  @Watch("$route")
  routeChanged() {
    this.accountsSearchTerm = "";
    this.accountViewSearchTerm = "";
  }
}

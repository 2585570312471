
















import { Component, Vue, PropSync, Prop, Watch } from "vue-property-decorator";
import AccountManagers from "@/components/AccountManagers.vue";
import { AccountsStoreModule } from "@/store/modules/accounts";

@Component({
  components: { AccountManagers },
})
export default class PermissionsStep extends Vue {
  @PropSync("valid") validCloned!: boolean;

  @Prop({ default: 3 })
  step!: boolean;

  searchTerm = "";

  selectedManagers: string[] = [];

  get managers() {
    return AccountsStoreModule.managers.filter((manager) =>
      manager.name.toLowerCase().includes(this.searchTerm)
    );
  }

  mounted() {
    this.$root.$on("reset-create-account-steps", () => {
      this.selectedManagers = [];
    });
  }

  updateSelectedManagers(list: string[]) {
    this.selectedManagers = list;
  }

  @Watch("step")
  newStep(newval: number) {
    if (newval == 4) this.validCloned = true;
  }
}

export default {
  bind(el: any) {
    try {
      const img = new Image();
      img.src = el.src;
      img.onerror = (err: any) => {
        el.src = require("@/assets/images/coin.png");
      };
    } catch (e) {
      console.log(e);
    }
  },
};

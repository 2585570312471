import {
  Module,
  VuexModule,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import axios from "axios";
import Vue from "vue";
import { SharedStoreModule } from "./shared";

export class FuturesAccount {
  public settings?: {
    riskLevel: number;
    strategies: any[];
  };
  public openOrders?: {
    bot: string;
    clOrdId: string;
    createdAt: string;
    id: string;
    leavesQty: number;
    orderId: string;
    orderQty: number;
    portfolio: string;
    price: number;
    side: string;
    status: string;
    stopPx: number;
    strategy: string;
    symbol: string;
    updatedAt: string;
    user: string;
  }[];
  public managers?: string[];
  public strategy?: string;
  public futuresAsset?: {
    symbol: string;
    balance: string;
  };
  public walletStatus?: {
    account: string;
    assets: any[];
    availableBalance: number;
    walletBalance: number;
    unrealisedPnl: number;
    createdAt: string;
    updatedAt: string;
    id: string;
  };
  public name?: string;
  public user?: { username: string; id: string };
  public exchanges?: [
    {
      _id: string;
      name: string;
      apiKey: string;
      secret: string;
      secure: boolean;
    }
  ];
  public transfers?: [any];
  public positions?: {
    avgEntryPrice: number;
    currentQty: number;
    symbol: string;
    unrealisedPnl: number;
    openOrders: any[];
    limitOpenOrders: any[];
    hasStopMarket: boolean;
  }[];

  public bot?: { name: string; id: string };
  public id?: string;
  constructor(init?: Partial<FuturesAccount>) {
    Object.assign(this, init);
    if (this.positions) {
      this.positions?.forEach((x) => {
        x.openOrders = [];
      });
    }
    if (this.openOrders) {
      this.positions?.forEach((x) => {
        (x.openOrders as any) = this.openOrders?.filter(
          (y) => y.symbol === x.symbol
        );
        (x.limitOpenOrders as any) = this.openOrders?.filter(
          (y) => y.symbol === x.symbol && y.price !== 0
        );
        (x.hasStopMarket as any) =
          this.openOrders?.filter(
            (y) =>
              y.symbol === x.symbol &&
              y.stopPx !== 0 &&
              y.stopPx !== 0 &&
              y.clOrdId &&
              y.clOrdId.indexOf(":") > -1
          ).length != 0;
      });
    }
  }
}
export interface FuturesAccounts {
  own: FuturesAccount[];
  managed: FuturesAccount[];
}

export interface Manager {
  id: string;
  name: string;
  username: string;
}

@Module({ dynamic: true, store: store, name: "futuresAccounts" })
class FuturesAccountsMod extends VuexModule {
  public strategies: any[] = [];
  public managers: Manager[] = [];
  public accounts: FuturesAccounts = {
    own: [],
    managed: [],
  };
  public accountsMap: {
    [accountId: string]: FuturesAccount;
  } = {};

  public accountsSearchTerm = "";
  public accountViewSearchTerm = "";

  @Action({ rawError: true })
  generateFuturesReport(id: string) {
    return axios
      .get(`/api/v1/accounts/futures/history/fullReport?accountId=${id}`)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Report generated!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getManagers() {
    return axios
      .get(`/api/v1/managers`)
      .then((resp) => {
        this.setManagers(resp.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getFuturesAccounts(userId: string) {
    return axios
      .get(`/api/v1/accounts/futures/${userId}`)
      .then((resp) => {
        this.setFuturesAccounts(resp.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getFuturesBTCMetrics(data: { accountId: string; daysBack: number }) {
    return axios
      .get(
        `/api/v1/accounts/futures/history/balance?accountId=${data.accountId}&daysBack=${data.daysBack}`,
        {
          headers: {
            NoErrorHandle: true,
          },
        }
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  @Action({ rawError: true })
  updateFuturesAccount(account: FuturesAccount) {
    return axios
      .post(`/api/v1/accounts/futures/single/${account.id}`, account)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Futures Account saved!",
        });
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action({ rawError: true })
  getStrategies() {
    return axios
      .get(`/api/v1/trading/strategies`)
      .then((resp) => {
        this.setStrategies(resp.data);
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action({ rawError: true })
  getFuturesAccountById(accountId: string) {
    return axios
      .get(`/api/v1/accounts/futures/single/${accountId}`)
      .then((resp) => {
        this.setFuturesAccountById(resp.data);
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Mutation
  setStrategies(values: any) {
    this.strategies = values;
  }

  @Mutation
  setFuturesAccountById(account: FuturesAccount) {
    Vue.set(this.accountsMap, account.id!, new FuturesAccount(account));
  }

  @Mutation
  setAccountSearchTerm(value: string) {
    this.accountsSearchTerm = value;
  }

  @Mutation
  setAccountViewSearchTerm(value: string) {
    this.accountViewSearchTerm = value;
  }

  @Mutation
  setEmptyAccountId(accountId: string) {
    this.accountsMap[accountId!] = new FuturesAccount();
  }

  @Mutation
  setFuturesAccounts(data: FuturesAccounts) {
    const accounts = {
      managed: [] as FuturesAccount[],
      own: [] as FuturesAccount[],
    };
    accounts.managed = data.managed.map((x) => new FuturesAccount(x));
    accounts.own = data.own.map((x) => new FuturesAccount(x));
    this.accounts = accounts;
  }

  @Mutation
  setManagers(data: any[]) {
    this.managers = data;
  }
}

export const FuturesAccountsStoreModule = getModule(FuturesAccountsMod);














































import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { ChartsStoreModule } from "@/store/modules/charts";
import { Account } from "@/store/modules/accounts";
import { maxValue, minValue, required } from "vuelidate/lib/validators";

@Component({
  components: {},
  validations: {
    form: {
      name: {
        required,
      },
      balanceInterval: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      buyThrottle: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      sellThrottle: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
    },
  },
})
export default class SpotWalletGeneralSettings extends Vue {
  expanded = [];
  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;

  form = {};

  mounted() {
    this.initForm();
  }

  initForm() {
    this.form = {
      name: this.account.name!,
      balanceInterval: this.account.settings!.balanceInterval,
      buyThrottle: this.account.settings!.buyThrottle,
      sellThrottle: this.account.settings!.sellThrottle,
    };
  }

  requiredErrors(fieldName: string) {
    const errors: string[] = [];
    if (this.$v.form[fieldName] && !this.$v.form[fieldName]!.$dirty)
      return errors;
    if (this.$v.form[fieldName]) {
      !this.$v.form[fieldName]!.required &&
        this.$v.form[fieldName]!.required !== undefined &&
        errors.push("This field is required");
      !this.$v.form[fieldName]!.minValue &&
        this.$v.form[fieldName]!.minValue !== undefined &&
        errors.push("Value out of boundries");
      !this.$v.form[fieldName]!.maxValue &&
        this.$v.form[fieldName]!.maxValue !== undefined &&
        errors.push("Value out of boundries");
    }
    return errors;
  }
}
















































































import { Account, AccountsStoreModule } from "@/store/modules/accounts";
import { AuthStoreModule } from "@/store/modules/auth";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import PieChart from "@/components/charts/PieChartCustom.vue";
import { ChartsStoreModule } from "@/store/modules/charts";

@Component({
  components: {
    PieChart,
  },
})
export default class ImportAssetsModal extends Vue {
  @PropSync("dialog") visible!: boolean;

  @Prop({
    default: () => {
      return new Account();
    },
  })
  account!: Account;

  selectedAccountId = "";

  get selectedAccount() {
    return this.accountsOwned.find((x) => x.id === this.selectedAccountId)
      ? this.accountsOwned.find((x) => x.id === this.selectedAccountId)
      : this.accountsManaged.find((x) => x.id === this.selectedAccountId);
  }

  get accountsOwned() {
    if (AccountsStoreModule.accounts.own)
      return AccountsStoreModule.accounts.own.filter(
        (x) => x.id !== this.account.id
      );
    else return [];
  }

  get accountsManaged() {
    if (AccountsStoreModule.accounts.managed)
      return AccountsStoreModule.accounts.managed.filter(
        (x) =>
          this.accountsOwned.find((y) => x.id === y.id) === undefined &&
          x.id !== this.account.id
      );
    else return [];
  }

  mounted() {
    AccountsStoreModule.getAccounts(AuthStoreModule.user.id);
  }

  get charts() {
    return ChartsStoreModule;
  }

  get pieChartData() {
    let data = JSON.parse(JSON.stringify(ChartsStoreModule.chartData.Pie.data));
    data.labels = this.selectedAccount!.portfolio!.map((coin) => coin.asset);
    data.datasets[0].data = this.selectedAccount!.portfolio!.map(
      (coin) => +coin.percentage
    );
    return data;
  }

  async save() {
    let account: any = {
      ...this.account,
    };
    let list: any[] = [];
    account.portfolio?.forEach((x: any) => {
      if (
        this.selectedAccount!.portfolio?.find(
          (y) => y.asset.toLowerCase() === x.asset.toLowerCase()
        ) === undefined
      ) {
        list.push({
          asset: x.asset,
          buyThrottle: null,
          edit: true,
          percentage: 0,
          sellThrottle: null,
        });
      }
    });

    account.portfolio = list.concat(
      this.selectedAccount?.portfolio
        ?.filter((x) => list.find((y) => y.asset === x.asset) === undefined)
        .map((x) => {
          return {
            asset: x.asset,
            buyThrottle: null,
            edit: true,
            percentage: x.percentage,
            sellThrottle: null,
          };
        })
    );
    let response = await AccountsStoreModule.updateAccount(account);
    if (response) {
      this.visible = false;
      AccountsStoreModule.setAccountById(account);
      setTimeout(() => {
        AccountsStoreModule.getAccounts(AuthStoreModule.user.id);
        this.selectedAccountId = "";
        this.$root.$emit("update-account");
      }, 0);
    }
  }
}

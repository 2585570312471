










































import { Component, Vue, PropSync, Watch, Prop } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { handleFormErrors } from "@/helpers/formValidations";

@Component({
  components: {},
  methods: { handleFormErrors },
  validations: {
    form: {
      name: {
        required,
      },
      strategy: {
        required,
      },
      bot: {
        required,
      },
    },
  },
})
export default class BasicStep extends Vue {
  @PropSync("valid") validCloned!: boolean;

  @Prop({ default: 1 })
  step!: boolean;

  @Watch("step")
  newStep(newval: number, oldval: number) {
    if (oldval == 1) this.$v.form.$touch();
  }

  strategies = [
    {
      label: "Scale v1",
      key: "scale_v1",
    },
  ];

  bots = [
    {
      label: "Bot #1",
      key: "bot_id",
    },
  ];

  form = {
    name: "",
    strategy: "scale_v1",
    bot: "",
  };

  mounted() {
    this.$root.$on("reset-create-account-steps", () => {
      this.form = {
        name: "",
        strategy: "scale_v1",
        bot: "",
      };
      setTimeout(() => {
        this.$v.form.$reset();
      }, 0);
    });
  }

  @Watch("form", { deep: true })
  formChanged() {
    this.validCloned = !this.$v.form.$invalid;
  }
}

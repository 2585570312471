







































































































import { Component, Vue } from "vue-property-decorator";
import { AccountsStoreModule } from "@/store/modules/accounts";
import { AuthStoreModule } from "@/store/modules/auth";
import AccountRow from "@/components/AccountRow.vue";
import AccountRowMobile from "@/components/AccountRowMobile.vue";
import AccountsSort from "@/components/AccountsSort.vue";
import CreateAccountStepperModal from "@/components/modals/create-account-stepper-modal/CreateAccountStepperModal.vue";

@Component({
  components: {
    AccountsSort,
    AccountRow,
    AccountRowMobile,
    CreateAccountStepperModal,
  },
})
export default class Accounts extends Vue {
  showAddAccountModal = false;
  overlay = false;

  managedAccountsSort = {
    key: "walletBalance",
    keyType: "number",
    label: "BTC Value High",
    asc: false,
  };

  ownAccountsSort = {
    key: "walletBalance",
    keyType: "number",
    label: "BTC Value High",
    asc: false,
  };

  get managedAccountsTotalBTC() {
    return this.managedAccounts.reduce((sum, a) => sum + a.walletBalance!, 0);
  }

  get ownedAccountsTotalBTC() {
    return this.ownedAccounts.reduce((sum, a) => sum + a.walletBalance!, 0);
  }

  get accounts() {
    return AccountsStoreModule.accounts;
  }

  get managedAccounts() {
    return this.accounts.managed
      ? this.accounts.managed
          .filter(
            (account) =>
              account.user?.id !== AuthStoreModule.user.id &&
              account.name
                ?.toLowerCase()
                ?.includes(AccountsStoreModule.accountsSearchTerm.toLowerCase())
          )
          .sort((a: any, b: any) => {
            if (this.managedAccountsSort.keyType === "string") {
              if (
                a[this.managedAccountsSort.key].toLowerCase() >
                b[this.managedAccountsSort.key].toLowerCase()
              ) {
                return this.managedAccountsSort.asc ? 1 : -1;
              } else {
                return this.managedAccountsSort.asc ? -1 : 1;
              }
            } else {
              if (
                a[this.managedAccountsSort.key] >
                b[this.managedAccountsSort.key]
              ) {
                return this.managedAccountsSort.asc ? 1 : -1;
              } else {
                return this.managedAccountsSort.asc ? -1 : 1;
              }
            }
          })
      : [];
  }

  get ownedAccounts() {
    return this.accounts.own
      ? this.accounts.own
          .filter((account) =>
            account.name
              ?.toLowerCase()
              ?.includes(AccountsStoreModule.accountsSearchTerm.toLowerCase())
          )
          .sort((a: any, b: any) => {
            if (this.ownAccountsSort.keyType === "string") {
              if (
                a[this.ownAccountsSort.key].toLowerCase() >
                b[this.ownAccountsSort.key].toLowerCase()
              ) {
                return this.ownAccountsSort.asc ? 1 : -1;
              } else {
                return this.ownAccountsSort.asc ? -1 : 1;
              }
            } else {
              if (a[this.ownAccountsSort.key] > b[this.ownAccountsSort.key]) {
                return this.ownAccountsSort.asc ? 1 : -1;
              } else {
                return this.ownAccountsSort.asc ? -1 : 1;
              }
            }
          })
      : [];
  }

  async mounted() {
    this.overlay = true;
    await AccountsStoreModule.getAccounts(AuthStoreModule.user.id);
    this.overlay = false;
  }

  toggleAddAccountModal(value: boolean) {
    this.showAddAccountModal = value;
  }

  updateOwnAccountsSort(value: {
    label: string;
    key: string;
    asc: boolean;
    keyType: string;
  }) {
    this.ownAccountsSort = value;
  }

  updateManagedAccountsSort(value: {
    label: string;
    key: string;
    asc: boolean;
    keyType: string;
  }) {
    this.managedAccountsSort = value;
  }
}

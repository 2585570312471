


































import { AccountsStoreModule } from "@/store/modules/accounts";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CommentsWidget extends Vue {
  @Prop({ default: false }) isFutures!: boolean;

  @Prop({
    default: () => {
      return "";
    },
  })
  accountId!: string;

  message = "";
  comments = [];

  mounted() {
    this.getComments();
  }

  add() {
    AccountsStoreModule.addAccountComment({
      accountId: this.accountId,
      text: this.message,
    }).then(() => {
      this.getComments();
      this.message = "";
    });
  }

  getComments() {
    AccountsStoreModule.getAccountComments(this.accountId).then((data: any) => {
      this.comments = data.sort((a: any, b: any) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    });
  }
}



















































import { Account, AccountsStoreModule } from "@/store/modules/accounts";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import WalletBalanceChart from "@/components/charts/WalletBalanceChart.vue";
import AssetsRatioChart from "@/components/charts/AssetsRatioChart.vue";

@Component({
  components: { WalletBalanceChart, AssetsRatioChart },
})
export default class AnalyticsModal extends Vue {
  walletBalanceSeries: any = [];
  ratioSeries: any = [];
  overlay: boolean[] = [];
  tab = 0;

  @PropSync("dialog") visible!: boolean;

  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;

  @Watch("visible")
  handle(val: boolean) {
    if (val) {
      this.init();
    } else {
      this.tab = 0;
    }
  }

  initAssetsRatio() {
    this.overlay.push(true);
    this.ratioSeries = [];
    AccountsStoreModule.getPortfolioDominanceMetrics({
      accountId: this.account.id!,
      daysBack: 90,
    })
      .then((resp) => {
        Object.keys(resp.series).forEach((key: string) => {
          this.ratioSeries.push({
            name: key,
            data: resp.series[key],
          });
        });
        resp.dates.forEach((date: string, index: number) => {
          for (let i = 0; i < this.ratioSeries.length; i++) {
            this.ratioSeries[i].data[index] = [
              date,
              this.ratioSeries[i].data[index],
            ];
          }
        });
      })
      .finally(() => {
        this.overlay.pop();
      });
  }

  initWalletBalance() {
    this.overlay.push(true);
    this.walletBalanceSeries = [
      {
        name: "BTC",
        data: [],
      },
      {
        name: "USD",
        data: [],
      },
    ];
    AccountsStoreModule.getBTCMetrics({
      accountId: this.account.id!,
      daysBack: 90,
    })
      .then((resp) => {
        resp.map((x: any) => {
          this.walletBalanceSeries[0].data.push([x.createdAt, x.walletBalance]);
          this.walletBalanceSeries[1].data.push([x.createdAt, x.dollarBalance]);
        });
      })
      .finally(() => {
        this.overlay.pop();
      });
  }

  init() {
    this.initWalletBalance();
    this.initAssetsRatio();
  }
}

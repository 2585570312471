




















import { Component, Vue } from "vue-property-decorator";
import WalletBalanceWidget from "@/components/widgets/WalletBalanceWidget.vue";
import ManagePortfoliosWidget from "@/components/widgets/ManagePortfoliosWidget.vue";
import HODLWidget from "@/components/widgets/HODLWidget.vue";
import ProfitWidget from "@/components/widgets/ProfitWidget.vue";
import AssetsWidget from "@/components/widgets/AssetsWidget.vue";
import GaugeChartWidget from "@/components/widgets/GaugeChartWidget.vue";
import SettingsWidget from "@/components/widgets/SettingsWidget.vue";
import TerminalWidget from "@/components/widgets/TerminalWidget.vue";
import StrategyWidget from "@/components/widgets/StrategyWidget.vue";
import InfoWidget from "@/components/widgets/InfoWidget.vue";
import PortfolioFeedWidget from "@/components/widgets/PortfolioFeedWidget.vue";
import AssetsDistributionWidget from "@/components/widgets/AssetsDistributionWidget.vue";
import QuickBroadcastWidget from "@/components/widgets/QuickBroadcastWidget.vue";
import { FuturesAccountsStoreModule } from "@/store/modules/futuresAccounts";
import DownloadReportWidget from "@/components/widgets/DownloadReportWidget.vue";
import CommentsWidget from "@/components/widgets/CommentsWidget.vue";

@Component({
  components: {
    WalletBalanceWidget,
    ManagePortfoliosWidget,
    HODLWidget,
    ProfitWidget,
    AssetsWidget,
    AssetsDistributionWidget,
    SettingsWidget,
    GaugeChartWidget,
    QuickBroadcastWidget,
    TerminalWidget,
    StrategyWidget,
    InfoWidget,
    PortfolioFeedWidget,
    DownloadReportWidget,
    CommentsWidget,
  },
})
export default class FuturesAccountViewImproved extends Vue {
  showAccountSettingsModal = false;
  showChartModal = false;

  get accountId() {
    return this.$route.params["id"];
  }

  get account() {
    let acct = FuturesAccountsStoreModule.accountsMap[this.accountId];
    acct.walletStatus!.assets = acct.walletStatus!.assets.filter(
      (a: any) => a.balance > 0.0001 || a.balance < -0.0001
    );
    return acct;
  }

  mounted() {}

  toggleAccountSettingsModal(value: boolean) {
    this.showAccountSettingsModal = value;
  }

  toggleChartModal(value: boolean) {
    this.showChartModal = value;
  }

  goBack() {
    this.$router.push("/");
  }
}

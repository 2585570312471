
















import { Component, Vue, Watch } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { Alert, SharedStoreModule } from "@/store/modules/shared";

@Component
export default class Alerts extends Vue {
  get alerts() {
    return SharedStoreModule.alerts;
  }

  @Watch("alerts", { deep: true })
  alertsChanged(newVal: Alert[], oldVal: Alert[]) {
    if (newVal.length !== oldVal.length) {
      setTimeout(() => {
        SharedStoreModule.showAlert(this.alerts.length - 1);
      }, 50);
      let id = this.alerts[this.alerts.length - 1].id!;
      setTimeout(() => {
        this.clearAlert(id);
      }, 3050);
    }
  }

  clearAlert(id: string) {
    SharedStoreModule.clearAlert(id);
  }
}


































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AccountsSort extends Vue {
  @PropSync("selectedValue") value!: {
    key: string;
    label: string;
  };

  @Prop({
    default: () => {
      return [
        {
          key: "name",
          keyType: "string",
          label: "Name A-Z",
          asc: true,
        },
        {
          key: "name",
          keyType: "string",
          label: "Name Z-A",
          asc: false,
        },
        {
          key: "walletBalance",
          keyType: "number",
          label: "BTC Value High",
          asc: false,
        },
        {
          key: "walletBalance",
          keyType: "number",
          label: "BTC Value Low",
          asc: true,
        },
      ];
    },
  })
  options!: any[];
}

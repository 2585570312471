













import { Component, Prop, Vue } from "vue-property-decorator";
// @ts-ignore
import VueGauge from "vue-gauge";
import { FuturesAccount } from "@/store/modules/futuresAccounts";
let GaugeChart = require("vue-gauge/assets/bundle.js");

@Component({
  components: {
    VueGauge,
  },
})
export default class GaugeChartWidget extends Vue {
  @Prop({
    default: () => {
      return new FuturesAccount({});
    },
  })
  account!: FuturesAccount;

  gauge: any = null;

  get needleValue() {
    let sum = 0;
    this.account.openOrders?.forEach((p) => {
      const weight = !p.strategy || p.strategy == "dinu" ? 1 : 0.2;
      sum = p.price * p.orderQty * weight + sum;
    });
    if (sum !== 0 && this.account.walletStatus?.walletBalance !== 0)
      return (sum / this.account.walletStatus?.walletBalance!).toFixed(2);
    return 0;
  }

  options = {
    needleValue: 0,
    hasNeedle: true,
    needleColor: "black",
    arcColors: [
      "rgb(61, 204, 91)",
      "rgb(239, 214, 19)",
      "#ff9300",
      "rgb(255, 84, 84)",
    ],
    arcDelimiters: [10, 25, 40],
    rangeLabel: ["0", "10"],
    needleStartValue: 50,
    centralLabel: "0",
  };

  mounted() {
    this.updateNeedle();
  }

  updateNeedle() {
    let element = document.querySelector("#gaugeChart");

    // get svg tags
    let [svg]: any = (element as any).getElementsByTagName("svg");

    // remove initial svg element created by the library
    svg.remove();
    this.options.centralLabel = `${this.needleValue}`;
    // reinit rendering GaugeChart and referencing it to global variable
    this.gauge = GaugeChart.gaugeChart(element, 250, this.options);
    this.gauge.updateNeedle(+this.needleValue * 10);
  }
}

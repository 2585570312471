





















































import { Component, Vue } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { AuthStoreModule } from "@/store/modules/auth";

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
})
export default class Login extends Vue {
  loading = false;
  form = {
    username: "",
    password: "",
  };

  async mounted() {
    await (this as any).$recaptchaLoaded();
    const token = await (this as any).$recaptcha("login");
    AuthStoreModule.setCaptchaToken(token);
  }

  get passwordErrors() {
    const errors: string[] = [];
    if (this.$v.form.password && !this.$v.form.password.$dirty) return errors;
    if (this.$v.form.password) {
      !this.$v.form.password.required && errors.push("Password is required");
    }
    return errors;
  }

  get usernameErrors() {
    const errors: string[] = [];
    if (this.$v.form.username && !this.$v.form.username.$dirty) return errors;
    if (this.$v.form.username) {
      !this.$v.form.username.required && errors.push("Username is required");
    }
    return errors;
  }

  async submit() {
    this.$v.form.$touch();
    if (this.$v.form.$pending || this.$v.form.$error) return;
    this.loading = true;
    const resp = await AuthStoreModule.login(this.form);
    this.loading = false;
    if (resp) {
      this.$router.push({ name: "Home" });
      // Set lock screen
      // AuthStoreModule.setLockScreen(true);
    } else {
      await (this as any).$recaptchaLoaded();
      const token = await (this as any).$recaptcha("login");
      AuthStoreModule.setCaptchaToken(token);
    }
  }
}

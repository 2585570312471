




































































import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { ChartsStoreModule } from "@/store/modules/charts";
import { Account } from "@/store/modules/accounts";

@Component({
  components: {},
})
export default class AssetsWidget extends Vue {
  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;

  headers = [
    { text: "NAME", value: "asset" },
    { text: "% SET", value: "percentage" },
    { text: "% NOW", value: "now" },
    { text: "TOTAL COINS", value: "amount" },
    { text: "BTC VALUE", value: "btc" },
  ];

  mounted() {}

  customSort(items: any, index: any, isDesc: any) {
    items.sort((a: any, b: any) => {
      if (index[0] === "now") {
        if (!isDesc[0]) {
          return a.btc > b.btc ? -1 : 1;
        } else {
          return b.btc > a.btc ? -1 : 1;
        }
      } else {
        if (!isDesc[0]) {
          return a[index[0]] < b[index[0]] ? -1 : 1;
        } else {
          return b[index[0]] < a[index[0]] ? -1 : 1;
        }
      }
    });
    return items;
  }
}































































































































import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { ChartsStoreModule } from "@/store/modules/charts";
import { Account } from "@/store/modules/accounts";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import AddAssetModal from "@/components/modals/AddAssetModal.vue";
import { SharedStoreModule } from "@/store/modules/shared";

@Component({
  components: { AddAssetModal },
  validations: {
    account: {
      portfolio: {
        $each: {
          asset: {
            required,
          },
          buyThrottle: {
            minValue: minValue(0),
            maxValue: maxValue(100),
          },
          sellThrottle: {
            minValue: minValue(0),
            maxValue: maxValue(100),
          },
        },
      },
    },
  },
})
export default class SpotWalletAssetsSettings extends Vue {
  expanded = [];
  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;
  headers = [
    { text: "Name", value: "asset" },
    { text: "Allocation", value: "allocation", width: "60%" },
    {
      text: "Percent",
      value: "percentage",
      class: "percentage",
      width: "150px",
    },
    { text: "", value: "delete", sortable: false, align: "center" },
  ];
  showAddAssetModal = false;

  mounted() {}

  toggleShowAddAssetModal(value: boolean) {
    this.showAddAssetModal = value;
  }

  getMaxPercentage(current: number): number {
    return (
      +current +
      this.account.portfolio!.reduce((accum: any, coin: any) => {
        return accum - +coin.percentage;
      }, 100)
    );
  }
  itemChangedi(ev: any, item: any) {
    if (
      +ev.srcElement.value <= this.getMaxPercentage(item.percentage) &&
      +ev.srcElement.value <= 100 &&
      +ev.srcElement.value >= 0
    ) {
      item.percentage = +ev.srcElement.value;
      (document.getElementById(item._id) as any).value = +ev.srcElement.value;
    } else {
      item.percentage = item.percentage + 0;
      (document.getElementById(item._id) as any).value = item.percentage;
    }
  }

  itemChanged(value: any, item: any) {
    if (
      +value <= this.getMaxPercentage(item.percentage) &&
      +value <= 100 &&
      +value >= 0
    ) {
      item.percentage = +value;
      (document.getElementById(item._id) as any).value = +value;
    } else {
      item.percentage = this.getMaxPercentage(item.percentage);
      setTimeout(() => {
        (document.getElementById(
          item._id
        ) as any).value = this.getMaxPercentage(item.percentage);
      }, 0);
    }
  }

  get portfolioPercentageSet() {
    return this.account.portfolio!.reduce((sum, a) => sum + +a.percentage!, 0);
  }

  customSort(items: any, index: any, isDesc: any) {
    items.sort((a: any, b: any) => {
      if (index[0] === "now") {
        if (!isDesc[0]) {
          return a.btc > b.btc ? -1 : 1;
        } else {
          return b.btc > a.btc ? -1 : 1;
        }
      } else {
        if (!isDesc[0]) {
          return a[index[0]] < b[index[0]] ? -1 : 1;
        } else {
          return b[index[0]] < a[index[0]] ? -1 : 1;
        }
      }
    });
    return items;
  }

  addAsset(name: string) {
    console.log(name);
    if (
      this.account.portfolio!.find(
        (x) => x.asset.toLowerCase() == name.toLowerCase()
      ) == undefined
    )
      this.account.portfolio!.unshift({
        _id: (Math.random() * 100000000).toString(),
        asset: name.toUpperCase(),
        percentage: 0,
        buyThrottle: null,
        sellThrottle: null,
        edit: true,
      } as any);
    else
      SharedStoreModule.setAlert({
        type: "error",
        text: "Asset already exists!",
      });
  }

  deleteAsset(item: any) {
    let it = this.account.portfolio!.find((x: any) => x.asset === item.asset);
    if (it)
      this.account.portfolio!.splice(this.account.portfolio!.indexOf(it), 1);
  }
}

















































// eslint-disable-next-line no-unused-vars
import { CollectorsReport, ReportsStoreModule } from "@/store/modules/reports";
import { Component, Vue } from "vue-property-decorator";
import CollectorsReportModal from "@/components/modals/CollectorsReportModal.vue";

@Component({
  components: { CollectorsReportModal },
})
export default class SettingsReports extends Vue {
  showAddCollectorReporModal = false;
  headers = [
    { text: "Name", value: "name" },
    { text: "Exchange", value: "exchange" },
    { text: "Markets", value: "markets", sortable: false },
    { text: "", value: "actions", sortable: false, cellClass: "action-col" },
  ];
  selectedReport: CollectorsReport = {} as CollectorsReport;

  mounted() {
    ReportsStoreModule.getReportsCollectors();
    ReportsStoreModule.getExchanges();
  }

  get collectorsReports() {
    return ReportsStoreModule.collectorsReports;
  }

  editReport(item: CollectorsReport) {
    this.selectedReport = item;
    this.toggleShowAddCollectorReporModal(true);
  }

  toggleShowAddCollectorReporModal(value: boolean) {
    this.showAddCollectorReporModal = value;
  }

  addNewCollectorReport() {
    this.selectedReport = {} as CollectorsReport;
    this.toggleShowAddCollectorReporModal(true);
  }
}








import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import AccountAssetsTable from "@/components/AccountAssetsTable.vue";

@Component({
  components: { AccountAssetsTable },
  validations: {
    form: {
      portfolio: {
        $each: {
          asset: {
            required,
          },
        },
      },
    },
  },
})
export default class AssetsStep extends Vue {
  @PropSync("valid") validCloned!: boolean;

  @Prop({ default: 3 })
  step!: number;

  @Prop({ default: false })
  visited!: boolean;

  form = {
    portfolio: [] as {
      asset: string;
      percentage: number;
      buyThrottle: number;
      sellThrottle: number;
    }[],
  };
  headers = [
    { text: "NAME", value: "asset" },
    { text: "PERCENTAGE OF PORTFOLIO", value: "percentage" },
    { text: "BUY THROTTLE / 24H", value: "buyThrottle", sortable: false },
    { text: "SELL THROTTLE / 24H", value: "sellThrottle", sortable: false },
    { text: "", value: "delete", sortable: false, align: "center" },
  ];
  mounted() {
    this.$root.$on("reset-create-account-steps", () => {
      this.form = {
        portfolio: [],
      };
    });
  }

  @Watch("form", { deep: true })
  formChanged() {
    // Only validate step when it's been visited
    if (this.visited) this.validCloned = !this.$v.form.$invalid;
  }

  getMaxPercentage(current: number): number {
    return (
      current +
      this.form.portfolio.reduce((accum: any, coin: any) => {
        return accum - coin.percentage;
      }, 100)
    );
  }
}

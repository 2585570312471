import {
  Module,
  VuexModule,
  getModule,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";

export const chartColors = [
  "#A686F7",
  "#00CC81",
  "#9acc00",
  "#FF5A4A",
  "#FFB700",
  "#478EFF",
];

@Module({ dynamic: true, store: store, name: "charts" })
class Charts extends VuexModule {
  public chartData = {
    Pie: {
      options: {
        // tooltips: {
        //   mode: "index",
        //   intersect: false,
        //   filter: function(tooltipItem: any) {
        //     if (tooltipItem.label !== "") return tooltipItem;
        //   },
        // },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
          labels: {
            usePointStyle: true,
            boxWidth: 8,
          },
        },
        layout: {
          padding: {
            bottom: 6,
          },
        },
      },
      data: {
        labels: ["Red", "Blue", "Yellow"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: [
              "#FF6633",
              "#FFB399",
              "#FF33FF",
              "#FFFF99",
              "#00B3E6",
              "#E6B333",
              "#3366E6",
              "#999966",
              "#99FF99",
              "#B34D4D",
              "#80B300",
              "#809900",
              "#E6B3B3",
              "#6680B3",
              "#66991A",
              "#FF99E6",
              "#CCFF1A",
              "#FF1A66",
              "#E6331A",
              "#33FFCC",
              "#66994D",
              "#B366CC",
              "#4D8000",
              "#B33300",
              "#CC80CC",
              "#66664D",
              "#991AFF",
              "#E666FF",
              "#4DB3FF",
              "#1AB399",
              "#E666B3",
              "#33991A",
              "#CC9999",
              "#B3B31A",
              "#00E680",
              "#4D8066",
              "#809980",
              "#E6FF80",
              "#1AFF33",
              "#999933",
              "#FF3380",
              "#CCCC00",
              "#66E64D",
              "#4D80CC",
              "#9900B3",
              "#E64D66",
              "#4DB380",
              "#FF4D4D",
              "#99E6E6",
              "#6666FF",
            ],
            hoverOffset: 4,
          },
        ],
      },
    },
  };
}

export const ChartsStoreModule = getModule(Charts);




































































import { Component, PropSync, Vue, Prop } from "vue-property-decorator";
import { handleFormErrors } from "@/helpers/formValidations";
import { Account, AccountsStoreModule } from "@/store/modules/accounts";
import { FuturesAccountsStoreModule } from "@/store/modules/futuresAccounts";

@Component({
  components: {},
  methods: {
    handleFormErrors,
  },
  validations: {
    form: {
      apiKey: {},
      name: {},
      secret: {},
      subaccount: {},
    },
  },
})
export default class AccountExchangeModal extends Vue {
  @PropSync("dialog") visible!: boolean;

  @Prop({
    default: () => {
      return new Account();
    },
  })
  account!: Account;

  @Prop({ default: false })
  isFutures!: boolean;

  form = {
    apiKey: "",
    name: "",
    secret: "",
    subaccount: "",
  };

  async save() {
    let account: any = {
      ...this.account,
    };
    if (
      this.form.apiKey == "" &&
      this.form.name == "" &&
      this.form.secret == ""
    )
      account.exchanges = [];
    else account.exchanges?.push(this.form);
    let response = this.isFutures
      ? await FuturesAccountsStoreModule.updateFuturesAccount(account)
      : await AccountsStoreModule.updateAccount(account);
    if (response) {
      this.visible = false;
      if (this.isFutures)
        FuturesAccountsStoreModule.setFuturesAccountById(account);
      AccountsStoreModule.setAccountById(account);
    }
  }
}

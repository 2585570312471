var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"widget-card terminal-widget"},[_c('v-tabs',[_c('v-tab',[_c('span',[_vm._v("Positions ("+_vm._s(_vm.account.positions.length)+")")])]),_c('v-tab',[_c('span',[_vm._v("Orders ("+_vm._s(_vm.account.openOrders.length)+")")])]),_c('v-tab',[_c('span',[_vm._v("Assets ("+_vm._s(_vm.account.walletStatus.assets.length)+")")])]),_c('v-tab',[_c('span',[_vm._v("Positions History ("+_vm._s(_vm.account.positionHistory.length)+")")])]),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0 default-table-widget",attrs:{"headers":_vm.headers,"items":_vm.account.positions,"items-per-page":10},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.currentQty >= 0 ? 'positive' : 'negative'},[_c('span',[_vm._v(" "+_vm._s(item.symbol)+" ")]),(!item.hasStopMarket)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,true)},[_vm._v(" No Stop-Loss set for this position ")]):_vm._e()],1)]}},{key:"item.entryValue",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" $"+_vm._s(Math.abs((item.currentQty * item.avgEntryPrice)).toFixed(2))+" ")])])]}},{key:"item.avgEntryPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" $"+_vm._s(item.avgEntryPrice.toFixed(5))+" ")])])]}},{key:"item.unrealisedPnl",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" $"+_vm._s(item.unrealisedPnl.toFixed(2))+" ")])])]}},{key:"header.actions",fn:function(ref){return [_c('v-btn',{staticClass:"action-btn",attrs:{"color":"warning","text":""},on:{"click":function($event){return _vm.marketAllPositions()}}},[_vm._v("Close all positions ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"actions"},[_c('v-menu',{attrs:{"open-on-hover":"","content-class":"menu-tooltip","top":"","offset-y":"","close-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-btn",attrs:{"color":"warning","text":""},on:{"click":function($event){return _vm.marketPosition(item, 100)}}},'v-btn',attrs,false),on),[_vm._v("Market")])]}}],null,true)},[_c('div',{staticClass:"btn-group"},[_c('v-btn',{attrs:{"text":"","elevation":"0"},on:{"click":function($event){return _vm.marketPosition(item, 100)}}},[_vm._v("100%")]),_c('v-btn',{attrs:{"text":"","elevation":"0"},on:{"click":function($event){return _vm.marketPosition(item, 75)}}},[_vm._v("75%")]),_c('v-btn',{attrs:{"text":"","elevation":"0"},on:{"click":function($event){return _vm.marketPosition(item, 50)}}},[_vm._v("50%")]),_c('v-btn',{attrs:{"text":"","elevation":"0"},on:{"click":function($event){return _vm.marketPosition(item, 25)}}},[_vm._v("25%")])],1)]),_c('span',{staticClass:"custom-divider warning--text"},[_vm._v("|")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","top":"","max-width":"290px","max-height":"300px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-btn",attrs:{"color":"warning","text":""}},'v-btn',attrs,false),on),[_vm._v("Limit ("+_vm._s(item.limitOpenOrders.length)+") ")])]}}],null,true)},[_c('v-list',{staticClass:"positions-menu-list"},_vm._l((item.limitOpenOrders),function(position){return _c('v-list-item',{key:((item.symbol) + "_" + (position.price))},[_vm._v(" "+_vm._s(position.orderQty)+" to be closed at "+_vm._s(position.price)+"$ "),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteOrder(item, position)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)}),1)],1)],1)]}}],null,true)})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0 default-table-widget",attrs:{"headers":_vm.headersAllOrders,"items":_vm.account.openOrders,"items-per-page":10},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"MMMM DD YYYY - HH:mm"))+" ")])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" $"+_vm._s(item.price || item.stopPx)+" ")])])]}},{key:"item.strategyType",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" "+_vm._s(item.clOrdId.split(":")[1] === "prd" ? "Predictum" : item.clOrdId.split(":")[1])+" ")])])]}},{key:"item.orderType",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" "+_vm._s(item.clOrdId.split(":")[2].indexOf("sl") !== -1 ? "Stop Loss" : (item.clOrdId.split(":")[2].indexOf("sm") !== -1 ? "Stop Market" : (item.clOrdId.split(":")[2].indexOf("limit") !== -1 ? "Limit" : (item.clOrdId.split(":")[2].indexOf("t") !== -1 ? "Take Profit" : item.clOrdId.split(":")[2]))))+" ")])])]}},{key:"header.actions",fn:function(ref){return [_c('v-btn',{staticClass:"action-btn",attrs:{"color":"warning","text":""},on:{"click":function($event){return _vm.deleteAllOrders()}}},[_vm._v("Cancel All ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteOrder(null, item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0 default-table-widget",attrs:{"headers":_vm.headersAllAssets,"items":_vm.account.walletStatus.assets,"items-per-page":10},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.symbol)+" ")])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" "+_vm._s(item.balance)+" "+_vm._s(item.symbol))])])]}}],null,true)})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0 default-table-widget",attrs:{"headers":_vm.headersPositionsHistory,"items":_vm.account.positionHistory,"items-per-page":10},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.entrySignal.signalDirection === 'long' ? 'positive' : 'negative'},[_c('span',[_vm._v(" "+_vm._s(item.symbol)+" ")])])]}},{key:"item.entryValue",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" $"+_vm._s(Math.abs((item.initialQty * item.avgEntryPrice)).toFixed(2))+" ")])])]}},{key:"item.avgEntryPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" $"+_vm._s(item.avgEntryPrice.toFixed(2))+" ")])])]}},{key:"item.strategy",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" "+_vm._s(item.entrySignal.strategy)+" ")])])]}},{key:"item.realisedPnl",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" $"+_vm._s(item.realisedPnl.toFixed(2))+" ")])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"DD MMM YYYY - HH:mm"))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,"DD MMM YYYY - HH:mm"))+" ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
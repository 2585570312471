



































































// eslint-disable-next-line no-unused-vars
import { CollectorsReport, ReportsStoreModule } from "@/store/modules/reports";
import { Component, Vue } from "vue-property-decorator";
import ReportModal from "@/components/modals/ReportModal.vue";

@Component({
  components: { ReportModal },
})
export default class Reports extends Vue {
  showAddReportModal = false;
  headers = [
    { text: "Name", value: "name" },
    { text: "User", value: "caller" },
    { text: "Start Date", value: "startDate" },
    { text: "Report Date", value: "updatedAt" },
    // { text: "Sentiment", value: "sentiment", sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  mounted() {
    ReportsStoreModule.getReportsCollectors();
    ReportsStoreModule.getReports();
    ReportsStoreModule.getExchanges();
  }

  get reports() {
    return ReportsStoreModule.reports;
  }

  toggleShowAddReporModal(value: boolean) {
    this.showAddReportModal = value;
  }

  addNewReport() {
    this.toggleShowAddReporModal(true);
  }
}

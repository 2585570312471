var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default-view spot-wallet-settings"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"header-title"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","icon":""},on:{"click":_vm.goBack}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v(" Settings ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto mr-2",attrs:{"elevation":"0","outlined":"","color":"primary","text":""},on:{"click":function($event){return _vm.toggleShowAccountManagersModal(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-multiple")])],1)]}}])},[_c('span',[_vm._v("Managers")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"elevation":"0","color":"primary","outlined":"","text":""},on:{"click":function($event){return _vm.clearAllThrottles()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Clear Assets Throttles")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"elevation":"0","outlined":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toggleShowImportAssetsModal(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus-box-multiple")])],1)]}}])},[_c('span',[_vm._v("Import Assets")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"elevation":"0","outlined":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toggleShowAccountExchangeModal(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Exhange Credentials")])]),_c('v-btn',{staticClass:"default-size small-icon",attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-save")]),_vm._v(" Save")],1)],1),_c('div',{staticClass:"content grid-view"},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col w-33"},[_c('v-card',{staticClass:"widget-card"},[_c('SpotWalletGeneralSettings',{ref:"generalSettings",attrs:{"account":_vm.account}})],1)],1),_c('div',{staticClass:"custom-col w-66"},[_c('SpotWalletAssetsSettings',{ref:"tableSettings",attrs:{"account":_vm.account}})],1)])]),_c('ImportAssetsModal',{attrs:{"dialog":_vm.showImportAssetsModal,"account":_vm.account},on:{"update:dialog":function($event){return _vm.toggleShowImportAssetsModal(false)}}}),_c('AccountExchangeModal',{attrs:{"dialog":_vm.showAccountExchangeModal,"account":_vm.account},on:{"update:dialog":function($event){return _vm.toggleShowAccountExchangeModal(false)}}}),_c('AccountManagersModal',{attrs:{"dialog":_vm.showAccountManagersModal,"account":_vm.account},on:{"update:dialog":function($event){return _vm.toggleShowAccountManagersModal(false)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
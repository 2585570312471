





















































































import { Component, Vue } from "vue-property-decorator";
import { FuturesAccountsStoreModule } from "@/store/modules/futuresAccounts";
import { AuthStoreModule } from "@/store/modules/auth";
import FuturesAccountRow from "@/components/FuturesAccountRow.vue";
import FuturesAccountRowMobile from "@/components/FuturesAccountRowMobile.vue";
import AccountsSort from "@/components/AccountsSort.vue";
import CreateAccountStepperModal from "@/components/modals/create-account-stepper-modal/CreateAccountStepperModal.vue";

@Component({
  components: {
    AccountsSort,
    FuturesAccountRow,
    FuturesAccountRowMobile,
    CreateAccountStepperModal,
  },
})
export default class FuturesAccount extends Vue {
  showAddAccountModal = false;
  overlay = false;
  sortOptions = [
    {
      key: "name",
      keyType: "string",
      label: "Name A-Z",
      asc: true,
    },
    {
      key: "name",
      keyType: "string",
      label: "Name Z-A",
      asc: false,
    },
    {
      key: "walletBalance",
      parentKey: "walletStatus",
      keyType: "number",
      label: "USD Value High",
      asc: false,
    },
    {
      key: "walletBalance",
      parentKey: "walletStatus",
      keyType: "number",
      label: "USD Value Low",
      asc: true,
    },
  ];

  managedAccountsSort: any = {
    key: "walletBalance",
    keyType: "number",
    parentKey: "walletStatus",
    label: "USD Value High",
    asc: false,
  };

  ownAccountsSort: any = {
    key: "walletBalance",
    keyType: "number",
    parentKey: "walletStatus",
    label: "USD Value High",
    asc: false,
  };

  get ownedAccountsTotalUSD() {
    return this.ownedAccounts.reduce(
      (sum, a) => sum + a.walletStatus?.walletBalance!,
      0
    );
  }

  get managedAccountsTotalUSD() {
    return this.managedAccounts.reduce(
      (sum, a) => sum + a.walletStatus?.walletBalance!,
      0
    );
  }

  get accounts() {
    return FuturesAccountsStoreModule.accounts;
  }

  get managedAccounts() {
    return this.accounts.managed
      ? this.accounts.managed
          .filter(
            (account) =>
              account.user?.id !== AuthStoreModule.user.id &&
              account.name
                ?.toLowerCase()
                ?.includes(
                  FuturesAccountsStoreModule.accountsSearchTerm.toLowerCase()
                )
          )
          .sort((a: any, b: any) => {
            if (this.managedAccountsSort.keyType === "string") {
              if (
                a[this.managedAccountsSort.key].toLowerCase() >
                b[this.managedAccountsSort.key].toLowerCase()
              ) {
                return this.managedAccountsSort.asc ? 1 : -1;
              } else {
                return this.managedAccountsSort.asc ? -1 : 1;
              }
            } else {
              if (this.managedAccountsSort.parentKey) {
                if (
                  a[this.managedAccountsSort.parentKey][
                    this.managedAccountsSort.key
                  ] >
                  b[this.managedAccountsSort.parentKey][
                    this.managedAccountsSort.key
                  ]
                ) {
                  return this.managedAccountsSort.asc ? 1 : -1;
                } else {
                  return this.managedAccountsSort.asc ? -1 : 1;
                }
              } else {
                if (
                  a[this.managedAccountsSort.key] >
                  b[this.managedAccountsSort.key]
                ) {
                  return this.managedAccountsSort.asc ? 1 : -1;
                } else {
                  return this.managedAccountsSort.asc ? -1 : 1;
                }
              }
            }
          })
      : [];
  }

  get ownedAccounts() {
    return this.accounts.own
      ? this.accounts.own
          .filter((account) =>
            account.name
              ?.toLowerCase()
              ?.includes(
                FuturesAccountsStoreModule.accountsSearchTerm.toLowerCase()
              )
          )
          .sort((a: any, b: any) => {
            if (this.ownAccountsSort.keyType === "string") {
              if (
                a[this.ownAccountsSort.key].toLowerCase() >
                b[this.ownAccountsSort.key].toLowerCase()
              ) {
                return this.ownAccountsSort.asc ? 1 : -1;
              } else {
                return this.ownAccountsSort.asc ? -1 : 1;
              }
            } else {
              if (this.ownAccountsSort.parentKey && a[this.ownAccountsSort.parentKey] &&  b[this.ownAccountsSort.parentKey]) {
                if (
                  a[this.ownAccountsSort.parentKey][this.ownAccountsSort.key] >
                  b[this.ownAccountsSort.parentKey][this.ownAccountsSort.key]
                ) {
                  return this.ownAccountsSort.asc ? 1 : -1;
                } else {
                  return this.ownAccountsSort.asc ? -1 : 1;
                }
              } else {
                if (a[this.ownAccountsSort.key] > b[this.ownAccountsSort.key]) {
                  return this.ownAccountsSort.asc ? 1 : -1;
                } else {
                  return this.ownAccountsSort.asc ? -1 : 1;
                }
              }
            }
          })
      : [];
  }

  async mounted() {
    this.overlay = true;
    await FuturesAccountsStoreModule.getFuturesAccounts(
      AuthStoreModule.user.id
    );
    this.overlay = false;
  }

  toggleAddAccountModal(value: boolean) {
    this.showAddAccountModal = value;
  }

  updateOwnAccountsSort(value: {
    label: string;
    key: string;
    asc: boolean;
    keyType: string;
  }) {
    this.ownAccountsSort = value;
  }

  updateManagedAccountsSort(value: {
    label: string;
    key: string;
    asc: boolean;
    keyType: string;
  }) {
    this.managedAccountsSort = value;
  }
}


































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Account } from "@/store/modules/accounts";
import { AuthStoreModule } from "@/store/modules/auth";
import moment from "moment";

@Component({
  components: {},
})
export default class FuturesAccountRowMobile extends Vue {
  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;

  @Prop({
    default: false,
  })
  isFuture!: boolean;

  get isOwn() {
    return this.account.user?.id === AuthStoreModule.user.id;
  }

  get balancingNotOccured() {
    return (
      moment().diff(moment(this.account.lastBalancing?.createdAt), "minutes") /
        60 >
      this.account.settings?.balanceInterval!
    );
  }

  timeAgo(date: string) {
    return moment(date).fromNow();
  }

  openAccountView() {
    this.$router.push(`/futures-accounts/${this.account.id}`);
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"widget-card assets-spot-widget"},[_c('v-data-table',{staticClass:"default-table-widget",attrs:{"headers":_vm.headers,"items":_vm.account.portfolio,"custom-sort":_vm.customSort,"items-per-page":15,"sort-by":'now'},scopedSlots:_vm._u([{key:"item.asset",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"logo"},[_c('img',{directives:[{name:"coin-image-fallback",rawName:"v-coin-image-fallback"}],staticClass:"mr-2",attrs:{"src":item.asset.length > 1
              ? ("https://coinicons-api.vercel.app/api/icon/" + (item.asset.toLowerCase()))
              : ''}}),_c('span',[_vm._v(" "+_vm._s(item.asset)+" ")])])]}},{key:"item.now",fn:function(ref){
              var item = ref.item;
return [_c('span',[_c('span',[_vm._v(" "+_vm._s(((item.btc / _vm.account.walletBalance) * 100).toFixed(2))+" ")])])]}},{key:"item.toTrade",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"to-trade"},[_c('span',{class:[
            item.unscaledType === 'sell' ? 'red--text' : 'green--text' ]},[_vm._v(" "+_vm._s(item.unscaledAmt === 0 ? "-" : ((item.unscaledType) + " " + (Math.abs(item.unscaledAmt).toFixed( 4 ))))+" ")])])]}},{key:"item.chartHistory",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"chart-history clickable",on:{"click":function($event){return _vm.openChartModal(item)}}},[_c('v-sparkline',{attrs:{"value":_vm.value,"gradient":['#43425d', '#a3a0fb'],"smooth":10,"fill":false,"type":'trend',"auto-line-width":true,"auto-draw":""}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports-view default-view"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"header-title"},[_vm._v("Reports")]),_c('v-btn',{staticClass:"default-size small-icon ml-auto",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.addNewReport()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Generate Report")],1)],1),_c('div',{staticClass:"content"},[_c('v-data-table',{staticClass:"default-table-widget",attrs:{"headers":_vm.headers,"items":_vm.reports,"items-per-page":-1,"sort-by":'caller'},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.startDate,"DD MMMM YYYY - HH:mm"))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,"DD MMMM YYYY - HH:mm"))+" ")])]}},{key:"item.caller",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.username)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticClass:"td-none",attrs:{"href":'/api/v1/files/reports/intervalGrowth/' +
                  item.filename +
                  '.csv'}},[_c('v-btn',_vm._g(_vm._b({staticClass:"action-icon",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Download")])])]}}],null,true)}),_c('ReportModal',{attrs:{"dialog":_vm.showAddReportModal},on:{"update:dialog":function($event){return _vm.toggleShowAddReporModal(false)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
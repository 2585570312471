










import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { ChartsStoreModule } from "@/store/modules/charts";
import { Account } from "@/store/modules/accounts";

@Component({
  components: {},
})
export default class AnalyticsWidget extends Vue {
  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;

  mounted() {}

  @Emit("clicked")
  openModal() {}
}

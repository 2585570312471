



































import { AccountsStoreModule } from "@/store/modules/accounts";
import { FuturesAccountsStoreModule } from "@/store/modules/futuresAccounts";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DownloadReportWidget extends Vue {
  @Prop({ default: false }) isFutures!: boolean;

  @Prop({
    default: () => {
      return "";
    },
  })
  accountId!: string;

  isGenerated = false;
  generating = false;

  mounted() {}

  async generateReport() {
    this.generating = true;
    if (this.isFutures)
      FuturesAccountsStoreModule.generateFuturesReport(this.accountId)
        .then((x) => {
          if (x) this.isGenerated = true;
        })
        .finally(() => {
          this.generating = false;
        });
    else
      AccountsStoreModule.generateAccountReport(this.accountId)
        .then((x) => {
          if (x) this.isGenerated = true;
        })
        .finally(() => {
          this.generating = false;
        });
  }

  downloadReport() {
    const url = `/api/v1/files/reports/intervalGrowth/accountFullReport/${this.accountId}.csv`;
    let a = document.createElement("a");
    a.href = url;
    a.download = this.accountId;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}







































































import { Component, Vue, Watch } from "vue-property-decorator";
import { AuthStoreModule } from "@/store/modules/auth";

@Component
export default class LockScreen extends Vue {
  value = "";
  pinVisible = false;
  invalidPin = false;
  interv: any = null;
  get isLockScreen() {
    return AuthStoreModule.screenLocked;
  }

  @Watch("isLockScreen")
  changed(newv: any) {
    if (newv)
      setTimeout(() => {
        this.decodeText();
        this.interv = setInterval(() => {
          if (this.isLockScreen) this.decodeText();
        }, 10000);
      }, 100);
  }

  mounted() {
    window.addEventListener("keypress", (ev) => {
      if (this.isLockScreen)
        if (
          !document.activeElement ||
          (document.activeElement?.classList &&
            document.activeElement?.classList[0] !== "keyPad")
        ) {
          this.pinVisible = true;
          setTimeout(() => {
            let ref: any = this.$refs[`btn-1`];
            if (ref) {
              ref.value = ev.key;
              this.value = ev.key;
            }
            let ref2: any = this.$refs[`btn-2`];
            if (ref2) {
              ref2.focus();
            }
          }, 100);
        }
    });
  }

  setPinVisible(value: boolean) {
    this.pinVisible = value;
    setTimeout(() => {
      let ref: any = this.$refs[`btn-1`];
      if (ref) {
        ref.focus();
      }
    }, 100);
  }

  keypressed(ev: any, i: number) {
    setTimeout(() => {
      if (i === 1) this.value = "";
      this.value = this.value + ev.key;
      if (i !== 4) {
        (this.$refs[`btn-${i + 1}`] as HTMLElement).focus();
      } else {
        if (AuthStoreModule.pinCode === this.value) {
          this.pinVisible = false;
          this.invalidPin = false;
          AuthStoreModule.setLockScreen(false);
          if (this.interv) clearInterval(this.interv);
        } else {
          (this.$refs[`btn-1`] as any).value = "";
          (this.$refs[`btn-2`] as any).value = "";
          (this.$refs[`btn-3`] as any).value = "";
          (this.$refs[`btn-4`] as any).value = "";
          (this.$refs[`btn-1`] as HTMLElement).focus();
          this.invalidPin = true;
        }
      }
    }, 0);
  }

  decodeText() {
    let text = document.getElementsByClassName("decode-text")[0];

    // assign the placeholder array its places
    let state = [];
    for (let i = 0, j = text.children.length; i < j; i++) {
      text.children[i].classList.remove("state-1", "state-2", "state-3");
      state[i] = i;
    }

    // shuffle the array to get new sequences each time
    let shuffled = this.shuffle(state);

    for (let i = 0, j = shuffled.length; i < j; i++) {
      let child = text.children[shuffled[i]];
      let classes = child.classList;

      // fire the first one at random times
      let state1Time = Math.round(Math.random() * (2000 - 300)) + 50;
      if (classes.contains("text-animation")) {
        setTimeout(this.firstStages.bind(null, child), state1Time);
      }
    }
  }

  // send the node for later .state changes
  firstStages(child: any) {
    if (child.classList.contains("state-2")) {
      child.classList.add("state-3");
    } else if (child.classList.contains("state-1")) {
      child.classList.add("state-2");
    } else if (!child.classList.contains("state-1")) {
      child.classList.add("state-1");
      setTimeout(this.secondStages.bind(null, child), 100);
    }
  }
  secondStages(child: any) {
    if (child.classList.contains("state-1")) {
      child.classList.add("state-2");
      setTimeout(this.thirdStages.bind(null, child), 100);
    } else if (!child.classList.contains("state-1")) {
      child.classList.add("state-1");
    }
  }
  thirdStages(child: any) {
    if (child.classList.contains("state-2")) {
      child.classList.add("state-3");
    }
  }

  shuffle(array: any) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }
}

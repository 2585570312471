






















import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

@Component
export default class AccountManagers extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  managers!: any[];

  @PropSync("selectedManagers")
  selectedManagersLocal!: string[];

  mounted() {}
}













































































































import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import Slider from "@/components/shared/Slider.vue";
import { handleFormErrors } from "@/helpers/formValidations";
import {
  required,
  requiredIf,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import { BroadcastStoreModule } from "@/store/modules/broadcast";
import { FuturesAccount } from "@/store/modules/futuresAccounts";

@Component({
  components: { Slider },
  methods: {
    handleFormErrors,
  },
  validations() {
    return {
      form: {
        strategyType: { required },
        signalType: {
          required,
        },
        signalDirection: {
          required: requiredIf(() => {
            return (this as any).form.signalType === "entry";
          }),
        },
        market: { required },
        orderType: {
          required: requiredIf(() => {
            return (this as any).form.signalType === "entry";
          }),
        },
        price: {},
        updateType: {
          required: requiredIf(() => {
            return (this as any).form.signalType === "update";
          }),
        },
        value: {
          required: requiredIf(() => {
            return (this as any).form.signalType === "update";
          }),
        },
        percentage: { required,minValue: minValue(0), maxValue: maxValue(100) },
        leverage: { minValue: minValue(0), maxValue: maxValue(20) },
      },
    };
  },
})
export default class QuickBroadcastWidget extends Vue {
  @Prop({
    default: () => {
      return new FuturesAccount({});
    },
  })
  account!: FuturesAccount;

  selectedMarket: any = {};
  orderTypes = [
    "LIMIT",
    "MARKET",
    "STOP",
    "STOP_MARKET",
    "TAKE_PROFIT",
    "TAKE_PROFIT_MARKET",
    "TRAILING_STOP_MARKET",
  ];
  directionOptions = [
    {
      text: "Short",
      value: "short",
    },
    {
      text: "Long",
      value: "long",
    },
  ];

  broadcastOptions = [
    {
      text: "Position Entry",
      value: "entry",
    },
    {
      text: "Position Update",
      value: "update",
    },
  ];

  form = {
    strategyType: "dinu",
    market: "",
    signalType: "entry",
    signalDirection: "long",
    updateType: "",
    orderType: "MARKET",
    price: 0,
    value: 0,
    leverage: 0,
    bots: [] as string[],
    percentage: null as any,
  };

  get exchangeInfo() {
    return BroadcastStoreModule.exchangeInfo.symbols;
  }

  mounted() {
    BroadcastStoreModule.getExchangeInfo();
  }

  decreaseLeverage() {
    this.form.percentage =
      this.form.percentage > Math.floor(this.form.percentage)
        ? Math.floor(this.form.percentage)
        : Math.floor(this.form.percentage) - 1 >= 0
        ? Math.floor(this.form.percentage) - 1
        : 0;
  }

  increaseLeverage() {
    this.form.percentage =
      Math.floor(this.form.percentage) + 1 > 100
        ? 100
        : Math.floor(this.form.percentage) + 1;
  }

  percentageChanged(value: number) {
    this.form.percentage = value;
  }

  text(item: any) {
    if (item.contractType === "CURRENT_QUARTER") {
      return `${item.baseAsset}/${item.marginAsset} Futures`;
    }
    return `${item.baseAsset}/${item.marginAsset}`;
  }

  selectMarket(val: string) {
    this.selectedMarket = this.exchangeInfo.find((x: any) => x.symbol == val);
  }

  changeType(event: any) {
    this.form.signalType = event;
  }

  save() {
    if (this.form.signalType === "entry")
      return {
        strategyType: this.form.strategyType,
        signalType: this.form.signalType,
        orderType: this.form.orderType,
        market: this.form.market,
        signalDirection: this.form.signalDirection,
        price: this.form.price,
        leverage: this.form.percentage / 10,
        bots: [this.account.bot?.id],
      };
    else
      return {
        strategyType: this.form.strategyType,
        signalType: this.form.signalType,
        market: this.form.market,
        updateType: this.form.updateType,
        value: this.form.value,
        bots: [this.account.bot?.id],
      };
  }

  emit() {
    if (this.form.signalType === "entry")
      BroadcastStoreModule.emitBroadcastEntry(this.save());
    else BroadcastStoreModule.emitBroadcastUpdate(this.save());
  }
}














































































































































import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { handleFormErrors } from "@/helpers/formValidations";
import BasicStep from "@/components/modals/create-account-stepper-modal/steps/BasicStep.vue";
import SettingsStep from "@/components/modals/create-account-stepper-modal/steps/SettingsStep.vue";
import AssetsStep from "@/components/modals/create-account-stepper-modal/steps/AssetsStep.vue";
import PermissionsStep from "@/components/modals/create-account-stepper-modal/steps/PermissionsStep.vue";

@Component({
  components: { BasicStep, SettingsStep, AssetsStep, PermissionsStep },
  methods: {
    handleFormErrors,
  },
})
export default class CreateAccountStepperModal extends Vue {
  @PropSync("dialog") visible!: boolean;
  stepper = 1;
  stepperHistory = [1];

  basicStepValid = false;
  settingsStepValid = false;
  assetsStepValid = false;
  permissionsStepValid = false;

  formSubmitted = false;

  close() {
    this.stepperHistory = [1];
    this.stepper = 1;
    this.$root.$emit("reset-create-account-steps");
    this.visible = false;
    this.basicStepValid = false;
    this.settingsStepValid = false;
    this.assetsStepValid = false;
    this.permissionsStepValid = false;
  }

  toggleBasicStepValid(value: boolean) {
    this.basicStepValid = value;
  }

  toggleSettingsStepValid(value: boolean) {
    this.settingsStepValid = value;
  }

  toggleAssetsStepValid(value: boolean) {
    this.assetsStepValid = value;
  }

  togglePermissionsStepValid(value: boolean) {
    this.permissionsStepValid = value;
  }

  get assetsStepVisited() {
    return this.stepperHistory.find((x) => x == 3) !== undefined;
  }

  get areStepsValid() {
    return (
      this.basicStepValid &&
      this.settingsStepValid &&
      (this.stepperHistory.find((x) => x == 3) === undefined
        ? true
        : this.assetsStepValid)
    );
  }

  submit() {
    this.formSubmitted = true;
  }

  stepInitted(index: number) {
    return this.stepperHistory.find((x) => x == index) !== undefined;
  }

  stepperChanged(step: number) {
    // first time step 3 is initialized make it true
    if (step == 3 && this.stepperHistory.find((x) => x == 3) === undefined) {
      this.assetsStepValid = true;
    }
    this.stepperHistory.push(step);
  }
}










































































































import { Component, Vue } from "vue-property-decorator";
import EmitBroadcastModal from "@/components/modals/EmitBroadcastModal.vue";
import { BroadcastStoreModule } from "@/store/modules/broadcast";

@Component({
  components: { EmitBroadcastModal },
})
export default class Broadcast extends Vue {
  options = [
    {
      label: "Entry",
      key: "entry",
    },
    {
      label: "Update",
      key: "update",
    },
  ];

  value = {
    label: "Entry",
    key: "entry",
  };

  showEmitBroadcastModal = false;
  entryHeaders = [
    { text: "Created At", value: "createdAt" },
    { text: "Caller", value: "caller" },
    { text: "Price", value: "price" },
    // { text: "Signal Type", value: "signalType" },
    { text: "Signal Direction", value: "signalDirection" },
    // { text: "Updated At", value: "updatedAt" },
  ];

  updateHeaders = [
    { text: "Created At", value: "createdAt" },
    { text: "Caller", value: "caller" },
    { text: "Value", value: "value" },
    { text: "Market", value: "market" },
    { text: "Update Type", value: "updateType" },
    // { text: "Signal Type", value: "signalType" },
    // { text: "Updated At", value: "updatedAt" },
  ];

  get entrySignals() {
    return BroadcastStoreModule.entrySignals;
  }

  get updateSignals() {
    return BroadcastStoreModule.updateSignals;
  }

  mounted() {
    BroadcastStoreModule.getEntrySignals();
    BroadcastStoreModule.getUpdateSignals();
  }

  emitBroadcast(data: any) {
    if (data.signalType === "entry")
      BroadcastStoreModule.emitBroadcastEntry(data).then((resp) => {
        if (resp) this.showEmitBroadcastModal = false;
      });
    else
      BroadcastStoreModule.emitBroadcastUpdate(data).then((resp) => {
        if (resp) this.showEmitBroadcastModal = false;
      });
  }
}

























































































































import { AccountsStoreModule } from "@/store/modules/accounts";
import { Component, Vue } from "vue-property-decorator";
import SpotWalletAssetsSettings from "@/components/widgets/spot-wallet-settings/SpotWalletAssetsSettings.vue";
import SpotWalletGeneralSettings from "@/components/widgets/spot-wallet-settings/SpotWalletGeneralSettings.vue";
import AccountExchangeModal from "@/components/modals/AccountExchangeModal.vue";
import AccountManagersModal from "@/components/modals/AccountManagersModal.vue";
import ImportAssetsModal from "@/components/modals/ImportAssetsModal.vue";

@Component({
  components: {
    SpotWalletAssetsSettings,
    SpotWalletGeneralSettings,
    AccountExchangeModal,
    AccountManagersModal,
    ImportAssetsModal,
  },
})
export default class SpotWalletSettings extends Vue {
  showAccountManagersModal = false;
  showAccountExchangeModal = false;
  showImportAssetsModal = false;

  get accountId() {
    return this.$route.params["id"];
  }

  get account() {
    return AccountsStoreModule.accountsMap[this.accountId];
  }

  toggleShowImportAssetsModal(value: boolean) {
    this.showImportAssetsModal = value;
  }

  toggleShowAccountManagersModal(value: boolean) {
    this.showAccountManagersModal = value;
  }

  toggleShowAccountExchangeModal(value: boolean) {
    this.showAccountExchangeModal = value;
  }

  goBack() {
    this.$router.push(`/accounts/${this.account.id}`);
  }

  clearAllThrottles() {
    this.account.portfolio?.forEach((x) => {
      (x.sellThrottle as any) = null;
      (x.buyThrottle as any) = null;
    });
    console.log("asd");
  }

  async save() {
    if (
      !(this.$refs["generalSettings"] as any).$v.form.$invalid &&
      !(this.$refs["tableSettings"] as any).$v.account.$invalid
    ) {
      // Remove generated ids for newly added assets
      this.account.portfolio!.map((x: any) => {
        x.asset = x.asset.toUpperCase();
        if (x.edit) delete x._id;
      });
      const account: any = {
        ...this.account,
        ...(this.$refs["generalSettings"] as any).form,
        settings: {
          buyThrottle: +(this.$refs["generalSettings"] as any).form.buyThrottle,
          sellThrottle: +(this.$refs["generalSettings"] as any).form
            .sellThrottle,
          balanceInterval: +(this.$refs["generalSettings"] as any).form
            .balanceInterval,
        },
      };
      let response = await AccountsStoreModule.updateAccount(account);
      if (response) {
        AccountsStoreModule.setAccountById(account);
      }
    }
  }
}

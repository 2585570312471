import {
  Module,
  VuexModule,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import axios from "axios";
import Vue from "vue";
import { SharedStoreModule } from "./shared";

export interface CollectorsReport {
  name: string;
  exchange: string;
  createdAt: Date;
  updatedAt: Date;
  markets: string[];
  id: string;
}

@Module({ dynamic: true, store: store, name: "signals" })
class ReportsModule extends VuexModule {
  collectorsReports: CollectorsReport[] = [];
  exchanges: string[] = [];
  exchangesMarkets: any = {}

  reports = [];

  @Action({ rawError: true })
  getExchanges() {
    return axios
      .get(`/dapi/v1/exchanges?enabled=1`)
      .then((resp) => {
        this.setExchanges(resp.data.data);
        return true;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getReportsCollectors() {
    return axios
      .get(`/api/v1/managers/collector/list`)
      .then((resp) => {
        this.setReportsCollectors(resp.data);
        return true;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  downloadReport(fileName: string) {
    return axios
      .get(`/api/v1/files/reports/intervalGrowth/${fileName}.csv`)
      .then((resp) => {
        return resp;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getExchangeMarkets(exchange: string) {
    return axios
      .get(`/dapi/v1/symbols?exchange=${exchange}&enabled=1`)
      .then((resp) => {
        this.setExchangeMarkets({ exchange, data: resp.data.data });
        return true;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  generateReport(report: any) {
    return axios
      .post(`/api/v1/managers/collector/reports/intervalGrowth`, report)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Report generated saved!",
        });
        return true;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getReports() {
    return axios
      .get(`/api/v1/managers/collector/reports/intervalGrowth`)
      .then((resp) => {
        this.setReports(resp.data);
        return resp;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  putReportsCollector(report: CollectorsReport) {
    return axios
      .put(`/api/v1/managers/collector/list`, report)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Collectors Report saved!",
        });
        return true;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  postReportsCollector(report: CollectorsReport) {
    return axios
      .post(`/api/v1/managers/collector/list`, report)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Collectors Report saved!",
        });
        return true;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Mutation
  setReportsCollectors(values: any[]) {
    this.collectorsReports = values;
  }

  @Mutation
  setReports(values: any) {
    this.reports = values;
  }

  @Mutation
  setExchanges(values: any) {
    this.exchanges = values;
  }

  @Mutation
  setExchangeMarkets(values: any) {
    Vue.set(this.exchangesMarkets, values.exchange, values.data);
  }
}

export const ReportsStoreModule = getModule(ReportsModule);


















































import { Component, Vue } from "vue-property-decorator";
import { AccountsStoreModule } from "@/store/modules/accounts";
import WalletBalanceWidget from "@/components/widgets/WalletBalanceWidget.vue";
import ManagePortfoliosWidget from "@/components/widgets/ManagePortfoliosWidget.vue";
import HODLWidget from "@/components/widgets/HODLWidget.vue";
import ProfitWidget from "@/components/widgets/ProfitWidget.vue";
import DownloadReportWidget from "@/components/widgets/DownloadReportWidget.vue";
import AssetsWidget from "@/components/widgets/AssetsWidget.vue";
import CommentsWidget from "@/components/widgets/CommentsWidget.vue";
import SettingsWidget from "@/components/widgets/SettingsWidget.vue";
import AnalyticsWidget from "@/components/widgets/AnalyticsWidget.vue";
import AssetsDistributionWidget from "@/components/widgets/AssetsDistributionWidget.vue";
import AnalyticsModal from "@/components/modals/AnalyticsModal.vue";

@Component({
  components: {
    WalletBalanceWidget,
    ManagePortfoliosWidget,
    HODLWidget,
    ProfitWidget,
    AssetsWidget,
    AssetsDistributionWidget,
    SettingsWidget,
    AnalyticsWidget,
    AnalyticsModal,
    DownloadReportWidget,
    CommentsWidget,
  },
})
export default class Accounts extends Vue {
  showAccountSettingsModal = false;
  showChartModal = false;

  get accountId() {
    return this.$route.params["id"];
  }

  get account() {
    return AccountsStoreModule.accountsMap[this.accountId];
  }

  mounted() {}

  toggleAccountSettingsModal(value: boolean) {
    this.showAccountSettingsModal = value;
  }

  toggleChartModal(value: boolean) {
    this.showChartModal = value;
  }

  goBack() {
    this.$router.push("/");
  }
}

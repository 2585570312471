export function handleFormErrors(fieldName: string, vueObj: any) {
  const errors: any = [];
  if (!vueObj.$v.form[fieldName].$dirty) return errors;
  if ("email" in vueObj.$v.form[fieldName]) {
    !vueObj.$v.form[fieldName].email &&
      errors.push("This email address is invalid");
  }
  if ("required" in vueObj.$v.form[fieldName]) {
    !vueObj.$v.form[fieldName].required &&
      errors.push("This field is required");
  }
  if ("minValue" in vueObj.$v.form[fieldName]) {
    !vueObj.$v.form[fieldName].minValue &&
      errors.push("Value out of boundries");
  }
  if ("maxValue" in vueObj.$v.form[fieldName]) {
    !vueObj.$v.form[fieldName].maxValue &&
      errors.push("Value out of boundries");
  }

  return errors;
}

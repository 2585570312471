































import { Component, PropSync, Vue, Prop } from "vue-property-decorator";
import { Account, AccountsStoreModule } from "@/store/modules/accounts";
import AccountManagers from "@/components/AccountManagers.vue";
import { FuturesAccountsStoreModule } from "@/store/modules/futuresAccounts";

@Component({
  components: { AccountManagers },
  methods: {},
})
export default class AccountManagersModal extends Vue {
  @PropSync("dialog") visible!: boolean;
  @Prop({ default: false }) isFutures!: boolean;

  @Prop({
    default: () => {
      return new Account();
    },
  })
  account!: Account;

  selectedManagers: string[] = [];

  get managers() {
    return AccountsStoreModule.managers.map((user: any) => {
      user.isOwner = user.id === this.account.user!.id;
      return user;
    });
  }

  mounted() {
    this.selectedManagers = this.account.managers!.concat([
      this.account.user!.id,
    ]);
  }

  updateSelectedManagers(list: string[]) {
    this.selectedManagers = list;
  }

  async save() {
    let account: any = {
      ...this.account,
    };
    account.managers = this.selectedManagers;
    let response = this.isFutures
      ? await FuturesAccountsStoreModule.updateFuturesAccount(account)
      : await AccountsStoreModule.updateAccount(account);
    if (response) {
      this.visible = false;
      if (this.isFutures)
        FuturesAccountsStoreModule.setFuturesAccountById(account);
      AccountsStoreModule.setAccountById(account);
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.account)?_c('v-card',{staticClass:"account-row",attrs:{"elevation":"0"}},[_c('router-link',{attrs:{"to":("/accounts/" + (_vm.account.id))}},[_c('span',{staticClass:"name-wr"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.account.name))]),(!_vm.isOwn)?_c('span',{staticClass:"user"},[_vm._v("Owner: "),(_vm.account.user)?_c('strong',[_vm._v(_vm._s(_vm.account.user.username))]):_vm._e()]):_vm._e()]),_c('span',{staticClass:"default-space bot-name"},[_c('span',{staticClass:"label"},[_vm._v("Bot Name")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.account.bot ? _vm.account.bot.name : ""))])]),_c('span',{staticClass:"default-space"},[_c('span',{staticClass:"label"},[_vm._v("Balance")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.account.walletBalance)+" "),_c('img',{staticClass:"ml-1",attrs:{"src":"https://cryptoicons.org/api/icon/btc/50","width":"15px"}})])]),_c('span',{staticClass:"default-space"},[_c('span',{staticClass:"label"},[_vm._v("Assets")]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.account.portfolio.length))])]),_c('span',{staticClass:"default-space"},[_c('span',{staticClass:"label"},[_vm._v("Buys")]),_c('span',{staticClass:"text green--text"},[_vm._v(_vm._s(_vm.account.lastBalancing && _vm.account.lastBalancing.buys ? _vm.account.lastBalancing.buys.length : 0)+" "),_c('v-icon',{staticClass:"green--text"},[_vm._v("mdi-arrow-left")])],1)]),_c('span',{staticClass:"default-space"},[_c('span',{staticClass:"label"},[_vm._v("Sells")]),_c('span',{staticClass:"text red--text"},[_vm._v(_vm._s(_vm.account.lastBalancing && _vm.account.lastBalancing.sales ? _vm.account.lastBalancing.sales.length : 0)+" "),_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-arrow-right")])],1)]),_c('span',{staticClass:"default-space"},[_c('span',{staticClass:"label"},[_vm._v("PNL "),(_vm.account.lastBalancing)?_c('span',{class:[
            _vm.account.lastBalancing.pnlSinceLast < 0
              ? 'red--text'
              : 'green--text' ]},[_vm._v("( "+_vm._s(( (_vm.account.lastBalancing.pnlSinceLast / _vm.account.walletBalance) * 100 ).toFixed(2))+" % )")]):_vm._e()]),(_vm.account.lastBalancing)?_c('span',{staticClass:"text",class:[
          _vm.account.lastBalancing.pnlSinceLast < 0
            ? 'red--text'
            : 'green--text' ]},[_vm._v(" "+_vm._s(_vm.account.lastBalancing.pnlSinceLast.toFixed(8))+" "),_c('img',{staticClass:"ml-1 mr-1",attrs:{"src":"https://cryptoicons.org/api/icon/btc/50","width":"15px"}})]):_c('span',[_vm._v("unknown")])]),_c('span',{staticClass:"default-space direction-row"},[(_vm.balancingNotOccured)?_c('v-tooltip',{staticClass:"tooltip-alert",attrs:{"max-width":"200px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-octagon")])]}}],null,false,3356839270)},[_c('span',[_vm._v("Last balancing was "+_vm._s(_vm._f("timeAgo")(_vm.account.lastBalancing.createdAt))+". The default balance interval is "+_vm._s(_vm.account.settings.balanceInterval === 1 ? ((_vm.account.settings.balanceInterval) + " hour") : ((_vm.account.settings.balanceInterval) + " hours"))+".")])]):_vm._e(),_c('div',[_c('span',{staticClass:"label"},[_vm._v("Last Scale Session")]),(_vm.account.lastBalancing)?_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm._f("timeAgo")(_vm.account.lastBalancing.createdAt))+" ("+_vm._s(_vm.account.settings.balanceInterval)+"h)")]):_c('span',[_vm._v("unknown")])])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }












import { Component, Vue } from "vue-property-decorator";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";

@Component({
  components: {
    Sidebar,
    Navbar,
  },
})
export default class Home extends Vue {
  mounted() {}
}

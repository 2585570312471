










import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class WalletBalanceChart extends Vue {
  chartOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#f2a900", "#1491fa", "#9C27B0"],
    stroke: {
      curve: "straight",
      width: 1,
    },
    title: {
      text: "Wallet Balance",
      align: "left",
    },
    xaxis: {
      type: "datetime",
    },
    legend: {
      showForSingleSeries: true,
    },
    yaxis: [
      {
        title: {
          text: "BTC",
        },
      },
      {
        opposite: true,
        title: {
          text: "USD",
        },
      },
    ],
    tooltip: {
      x: {
        show: true,
        format: "dd MMMM yyyy - HH:ss",
        formatter: undefined,
      },
    },
  };

  @Prop({
    default: () => {
      return [];
    },
  })
  series!: [];
}

import {
  Module,
  VuexModule,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import axios from "axios";
import { AccountsStoreModule } from "./accounts";

export interface User {
  username: string;
  password: string;
}

export interface CurrentUser {
  role: string;
  manager: boolean;
  username: string;
  email: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  id: string;
  pinCode: string;
}

export interface TokenInfo {
  token: string;
  expires: Date;
}

@Module({ dynamic: true, store: store, name: "auth" })
class Auth extends VuexModule {
  public captchaToken = "";
  public count = 0;
  public accessToken: TokenInfo = {} as TokenInfo;
  public user: CurrentUser = {} as CurrentUser;
  public screenLocked = false;

  get isAuthenticated() {
    return this.accessToken.token !== undefined;
  }

  get pinCode() {
    if (this.user && this.user.pinCode)
      return this.user.pinCode;
    return '0000';
  }

  get isManager() {
    return AccountsStoreModule.managers!.find((x) => x.id === AuthStoreModule.user.id);
  }

  @Action({ rawError: true })
  async init() {
    let screenLocked = localStorage.getItem('screenLocked');
    if (screenLocked && screenLocked == 'true')
      this.setLockScreen(true);
    let managersResponse = AccountsStoreModule.getManagers();
    return Promise.all([managersResponse, new Promise((res) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        this.setAccessToken(JSON.parse(token));
        const user = localStorage.getItem("user");
        if (user) this.setCurrentUser(JSON.parse(user));
      }
      res(true);
    })])

  }

  @Action({ rawError: true })
  login(user: User) {
    return axios
      .post("/api/v1/auth/login", {
        ...user,
        "g-recaptcha-response": this.captchaToken,
      })
      .then((resp) => {
        this.setAccessTokenResp(resp.data);
        this.init();
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Mutation
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    this.accessToken = {} as TokenInfo;
    this.user = {} as CurrentUser;
  }

  @Mutation
  setAccessToken(token: TokenInfo) {
    this.accessToken = token;
  }

  @Mutation
  setCurrentUser(user: CurrentUser) {
    this.user = user;
  }

  @Mutation
  setCaptchaToken(token: string) {
    this.captchaToken = token;
  }

  @Mutation
  setLockScreen(value: boolean) {
    this.screenLocked = value;
    localStorage.setItem('screenLocked', value ? 'true' : 'false')
  }

  @Mutation
  setAccessTokenResp(data: any) {
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("accessToken", JSON.stringify(data.tokens.access));
    localStorage.setItem("refreshToken", JSON.stringify(data.tokens.refresh));
  }
}

export const AuthStoreModule = getModule(Auth);

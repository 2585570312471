import {
  Module,
  VuexModule,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import axios from "axios";
import Vue from "vue";
import { SharedStoreModule } from "./shared";

export class Account {
  public settings?: {
    balanceInterval: number;
    buyThrottle: number;
    sellThrottle: number;
  };
  public managers?: string[];
  public walletBalance?: number;
  public balanceInterval?: number;
  public name?: string;
  public user?: { username: string; id: string };
  public exchanges?: [
    {
      _id: string;
      name: string;
      apiKey: string;
      secret: string;
      secure: boolean;
    }
  ];
  public portfolio?: {
    unscaledAmt: number;
    _id: string;
    asset: string;
    spree: number;
    percentage: number;
    amount: number;
    btc: number;
    unscaledType: string;
    buyThrottle: number;
    sellThrottle: number;
    bypassThrottle: boolean;
  }[];
  public createdAt?: Date;
  public updatedAt?: Date;
  public lastBalancing?: {
    pnlSinceLast: number;
    account: string;
    walletBalance: number;
    portfolio: {
      _id: string;
      asset: string;
      amount: number;
      btc: number;
    }[];
    buys: {
      _id: string;
      asset: string;
      amount: number;
      btc: number;
      price: number;
      orderId: string;
      clientOrderId: string;
    }[];
    sales: {
      _id: string;
      asset: string;
      amount: number;
      btc: number;
    }[];
    createdAt: Date | null;
    updatedAt: Date | null;
    id: string;
  };
  public wallet?: [];
  public bot?: { name: string; id: string };
  public deposits?: {
    _id: string;
    assets: {
      _id: string;
      amount: number;
      asset: string;
    }[];
    btc: number;
  }[];
  public unscaledAmt?: number;
  public id?: string;
  constructor(init?: Partial<Account>) {
    (this.lastBalancing = {
      pnlSinceLast: 0,
      account: "",
      walletBalance: 0,
      portfolio: [],
      buys: [],
      sales: [],
      createdAt: null,
      updatedAt: null,
      id: "",
    }),
      Object.assign(this, init);
    if (this?.portfolio && this.lastBalancing) {
      this.portfolio.map((coin) => {
        let latestPortfolio = this.lastBalancing!.portfolio.find(
          (x) => x.asset === coin.asset
        );
        if (latestPortfolio) {
          coin.amount = +latestPortfolio.amount.toFixed(4);
          coin.btc = latestPortfolio.btc ? +latestPortfolio.btc.toFixed(4) : 0;
          coin.unscaledType = coin.unscaledAmt > 0 ? "buy " : "sell";
        }
      });
    }
  }

  get hodl() {
    const assets: any = {};
    let hodlValue = 0;
    if (this.lastBalancing) {
      this.lastBalancing.portfolio.forEach((c: any) => {
        assets[c.asset] = {
          asset: c.asset,
          price: c.btc / c.amount,
          amount: c.amount,
          atStart: 0,
        };
      });
      this.deposits!.forEach((deposit: any) => {
        deposit.assets.forEach((c: any) => {
          if (assets[c.asset]) {
            assets[c.asset].atStart = assets[c.asset].atStart + c.amount;
          }
        });
      });
      this.lastBalancing.portfolio.forEach((c: any) => {
        hodlValue += assets[c.asset].atStart * assets[c.asset].price;
      });

      return Number(hodlValue.toFixed(6));
    }
  }

  get scaleProfit() {
    return this.walletBalance! - this.hodl!;
  }
}
export interface Accounts {
  own: Account[];
  managed: Account[];
}

export interface Manager {
  id: string;
  name: string;
  username: string;
}

@Module({ dynamic: true, store: store, name: "accounts" })
class AccountsMod extends VuexModule {
  public managers: Manager[] = [];
  public accounts = {} as Accounts;
  public accountsMap: {
    [accountId: string]: Account;
  } = {};

  public accountsSearchTerm = "";
  public accountViewSearchTerm = "";

  @Action({ rawError: true })
  generateAccountReport(id: string) {
    return axios
      .get(`/api/v1/accounts/history/fullReport?accountId=${id}`)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Report generated!",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getManagers() {
    return axios
      .get(`/api/v1/managers`)
      .then((resp) => {
        this.setManagers(resp.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  addAccountComment(data: any) {
    return axios
      .post(`/api/v1/accounts/comments`, data)
      .then((resp) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  }

  @Action({ rawError: true })
  getAccountComments(accountId: string) {
    return axios
      .get(`/api/v1/accounts/comments/${accountId}`)
      .then((resp: any) => {
        return resp.data.comments;
      })
      .catch((err) => {
        return false;
      });
  }

  @Action({ rawError: true })
  createAccount(body: { name: string; user: string }) {
    return axios
      .post(`/api/v1/accounts`, { body })
      .then((resp) => {
        this.getAccounts(body.user);
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action({ rawError: true })
  getBTCMetrics(data: { accountId: string; daysBack: number }) {
    return axios
      .get(
        `/api/v1/accounts/history/balance?accountId=${data.accountId}&daysBack=${data.daysBack}`
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getPortfolioDominanceMetrics(data: { accountId: string; daysBack: number }) {
    return axios
      .get(
        `/api/v1/accounts/history/ratio?accountId=${data.accountId}&daysBack=${data.daysBack}`
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getAccounts(userId: string) {
    return axios
      .get(`/api/v1/accounts/${userId}`)
      .then((resp) => {
        this.setAccounts(resp.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  deleteAccount(accountId: string) {
    return axios
      .delete(`/api/v1/accounts/single/${accountId}`)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Account deleted!",
        });
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action({ rawError: true })
  updateAccount(account: Account) {
    return axios
      .post(`/api/v1/accounts/single/${account.id}`, account)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Account saved!",
        });
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action({ rawError: true })
  getAccountById(accountId: string) {
    return axios
      .get(`/api/v1/accounts/single/${accountId}`)
      .then((resp) => {
        this.setAccountById(resp.data);
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action({ rawError: true })
  getAccountDepositHistory(data: { accountId: string; date: Date }) {
    const dateString = data.date.toISOString();
    return axios
      .get(`/api/v1/accounts/scale/${data.accountId}/${dateString}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        return false;
      });
  }

  @Mutation
  setAccountById(account: Account) {
    Vue.set(this.accountsMap, account.id!, new Account(account));
  }

  @Mutation
  setAccountSearchTerm(value: string) {
    this.accountsSearchTerm = value;
  }

  @Mutation
  setAccountViewSearchTerm(value: string) {
    this.accountViewSearchTerm = value;
  }

  @Mutation
  setEmptyAccountId(accountId: string) {
    this.accountsMap[accountId!] = new Account();
  }

  @Mutation
  setAccounts(data: Accounts) {
    const accounts = {
      managed: [] as Account[],
      own: [] as Account[],
    };
    accounts.managed = data.managed.map((x) => new Account(x));
    accounts.own = data.own.map((x) => new Account(x));
    this.accounts = accounts;
  }

  @Mutation
  setManagers(data: any[]) {
    this.managers = data;
  }
}

export const AccountsStoreModule = getModule(AccountsMod);

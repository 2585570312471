




































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ConfirmModal extends Vue {
  @Prop({ default: "Confirm" }) title!: string;
  @Prop({ default: "" }) text!: string;
  @Prop({ default: "" }) subtext!: string;
  @Prop({ default: "Yes" }) yesBtn!: string;
  @Prop({ default: "No" }) noBtn!: string;
  @Prop({ default: -1 }) state!: number;
  @PropSync("dialog") visible!: boolean;

  confirm() {
    this.$root.$emit("confirm-ok", true);
  }
}

import axios from "axios";
import { Commit } from "vuex";
import { AuthStoreModule } from "@/store/modules/auth";
import { SharedStoreModule } from "@/store/modules/shared";
import router from "@/router";

export default function setup(commit: Commit) {
  axios.interceptors.request.use(
    function (config) {
      if (!config.url?.includes("/v1/auth/login")) {
        config.headers.authorization = `Bearer ${AuthStoreModule.accessToken.token}`;
      }
      return config;
    },
    (error) => {
      console.log(error);

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        AuthStoreModule.logout();
        if (error.response.config.url !== '/api/v1/auth/login')
          router.push("/login");
      }
      let errorMessage = "";
      if (error) {
        if (error.response) {
          if (error.response.data && error.response.data.message)
            errorMessage = error.response.data.message;
          else if (error.response.data)
            errorMessage = error.response.data;
        } else {
          errorMessage = error;
        }
      } else {
        errorMessage = 'Unknown Error'
      }
      if (error.config.headers['NoErrorHandle'] === undefined)
        SharedStoreModule.setAlert({
          type: "error",
          text: errorMessage
        });
      return Promise.reject(error);
    }
  );
}






























































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { FuturesAccount } from "@/store/modules/futuresAccounts";
import { BroadcastStoreModule } from "@/store/modules/broadcast";

@Component({
  components: {},
})
export default class AssetsWidget extends Vue {
  @Prop({
    default: () => {
      return new FuturesAccount({});
    },
  })
  account!: FuturesAccount;

  headersAllAssets = [
    { text: "Symbol", value: "symbol" },
    { text: "Asset Balance", value: "balance" },
  ];

  headersAllOrders = [
    { text: "Created At", value: "createdAt" },
    { text: "Symbol", value: "symbol" },
    { text: "Price", value: "price" },
    { text: "Order Type", value: "orderType" },
    { text: "Strategy Type", value: "strategyType" },
    { text: "", value: "actions", sortable: false },
  ];

  headers = [
    { text: "Symbol", value: "symbol" },
    { text: "Size", value: "currentQty" },
    { text: "Position Value", value: "entryValue" },
    { text: "Avg Entry Price", value: "avgEntryPrice" },
    { text: "PNL", value: "unrealisedPnl" },
    { text: "", value: "actions", sortable: false },
  ];

  headersPositionsHistory = [
    { text: "Symbol", value: "symbol" },
    { text: "PNL", value: "realisedPnl" },
    { text: "Size", value: "initialQty" },
    { text: "Position Value", value: "entryValue" },
    { text: "Avg Entry Price", value: "avgEntryPrice" },
    { text: "Open Time", value: "createdAt" },
    { text: "Close Time", value: "updatedAt" },
    { text: "Strategy", value: "strategy" },
  ];

  mounted() {}

  marketAllPositions() {
    this.account.positions?.forEach((x) => {
      let payload = {
        bots: [this.account.bot?.id],
        market: x.symbol,
        signalType: "update",
        strategyType: "dinu",
        updateType: "CLOSE",
        value: 0,
      };
      BroadcastStoreModule.emitBroadcastUpdate(payload);
    });
  }

  marketPosition(item: any, percentage: number) {
    let payload = {
      bots: [this.account.bot?.id],
      market: item.symbol,
      signalType: "update",
      strategyType: "dinu",
      updateType: "PARTIAL_CLOSE",
      value: percentage / 100,
    };

    BroadcastStoreModule.emitBroadcastUpdate(payload);
  }

  deleteAllOrders() {
    this.account.openOrders!.map((position) => {
      BroadcastStoreModule.emitCloseOrder({
        bot: position.bot,
        orderId: position.clOrdId,
        market: position.symbol,
        strategyType: position.strategy,
      }).then(() => {
        this.account.openOrders = this.account.openOrders!.filter(
          (order: { clOrdId: any }) => order.clOrdId !== position.clOrdId
        );
      });
    });
  }

  deleteOrder(item: any, position: any) {
    BroadcastStoreModule.emitCloseOrder({
      bot: position.bot,
      orderId: position.clOrdId,
      market: position.symbol,
      strategyType: position.strategy,
    }).then(() => {
      if (item)
        item.openOrders = item.openOrders.filter(
          (order: { clOrdId: any }) => order.clOrdId !== position.clOrdId
        );
      this.account.openOrders = this.account.openOrders!.filter(
        (order: { clOrdId: any }) => order.clOrdId !== position.clOrdId
      );
    });
  }
}




















































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AccountExchangeModal from "@/components/modals/AccountExchangeModal.vue";
import AccountManagersModal from "@/components/modals/AccountManagersModal.vue";
import { FuturesAccountsStoreModule } from "@/store/modules/futuresAccounts";
import { required } from "vuelidate/lib/validators";

@Component({
  components: {
    AccountExchangeModal,
    AccountManagersModal,
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
})
export default class FuturesAccountSettings extends Vue {
  showAccountManagersModal = false;
  showAccountExchangeModal = false;
  selectedStrategyIndex = 0;

  defaultTargets = [
    { amt: 10, level: 1 },
    { amt: 15, level: 2 },
    { amt: 15, level: 3 },
    { amt: 15, level: 5 },
    { amt: 12.5, level: 8 },
    { amt: 12.5, level: 13 },
    { amt: 10, level: 21 },
  ];

  form = {
    name: "",
    strategies: [] as any[],
  };

  get accountId() {
    return this.$route.params["id"];
  }

  get account() {
    return FuturesAccountsStoreModule.accountsMap[this.accountId];
  }

  async mounted() {
    await FuturesAccountsStoreModule.getStrategies();
    this.initForm();
  }

  addTarget() {
    this.form.strategies[this.selectedStrategyIndex].targets.push({
      amt: 0,
      level: 0,
    });
  }

  deleteTarget(index: number) {
    this.form.strategies[this.selectedStrategyIndex].targets.splice(
      index,
      1
    );
  }

  requiredErrors(fieldName: string) {
    const errors: string[] = [];
    if (this.$v.form[fieldName] && !this.$v.form[fieldName]!.$dirty)
      return errors;
    if (this.$v.form[fieldName]) {
      !this.$v.form[fieldName]!.required &&
        this.$v.form[fieldName]!.required !== undefined &&
        errors.push("This field is required");
      !this.$v.form[fieldName]!.minValue &&
        this.$v.form[fieldName]!.minValue !== undefined &&
        errors.push("Value out of boundries");
      !this.$v.form[fieldName]!.maxValue &&
        this.$v.form[fieldName]!.maxValue !== undefined &&
        errors.push("Value out of boundries");
    }
    return errors;
  }

  toggleShowAccountManagersModal(value: boolean) {
    this.showAccountManagersModal = value;
  }

  toggleShowAccountExchangeModal(value: boolean) {
    this.showAccountExchangeModal = value;
  }

  goBack() {
    this.$router.push(`/futures-accounts/${this.account.id}`);
  }

  initForm() {
    this.form = {
      name: this.account.name!,
      strategies: FuturesAccountsStoreModule.strategies.map((x) => {
        let str = this.account.settings?.strategies.find(
          (y) => y.name === x.stratId
        );
        return {
          displayName: x.name,
          name: x.stratId,
          ratio: str && str.ratio ? str.ratio * 10 : 0,
          targets: x.targets
            ? x.targets.map((z: any) => {
                z.amt = z.amt * 10;
                z.level = z.amt * 10;
                return z;
              })
            : this.defaultTargets,
        };
      }),
    };
  }

  getMaxPercentageTarget(current: number, target: any[]): number {
    return (
      +current +
      target.reduce((accum: any, str: any) => {
        return accum - +str.amt;
      }, 100)
    );
  }

  getMaxPercentage(current: number): number {
    return (
      +current +
      this.form.strategies.reduce((accum: any, str: any) => {
        return accum - +str.ratio;
      }, 100)
    );
  }

  async save() {
    if (!this.$v.form.$invalid) {
      const account: any = {
        ...this.account,
        name: this.form.name,
      };
      account.settings.strategies = JSON.parse(
        JSON.stringify(this.form.strategies)
      ).map((x: any) => {
        x.ratio = x.ratio / 10;
        x.targets = x.targets.map((y: any) => {
          y.amt = y.amt / 10;
          y.level = y.level / 10;
          return y;
        });
        return x;
      });
      let response = await FuturesAccountsStoreModule.updateFuturesAccount(
        account
      );
      if (response) {
        FuturesAccountsStoreModule.setFuturesAccountById(account);
      }
    }
  }
}












































import { Component, Emit, PropSync, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ProfileModal extends Vue {
  @PropSync("dialog") visible!: boolean;

  assetName = "";

  @Watch("visible")
  reset() {
    this.assetName = "";
  }

  @Emit("update")
  save() {
    this.visible = false;
    return this.assetName;
  }
}

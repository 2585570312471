













































































































import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

@Component
export default class AssetsStep extends Vue {
  @PropSync("portfolio") localPortfolio!: {
    asset: string;
    percentage: number;
    buyThrottle: number;
    sellThrottle: number;
    bypassThrottle: boolean;
    id: string;
  }[];

  headers = [
    { text: "NAME", value: "asset" },
    { text: "% OF PORTFOLIO", value: "percentage" },
    { text: "BUY THROTTLE / 24H", value: "buyThrottle", sortable: false },
    {
      text: "SELL THROTTLE / 24H",
      value: "sellThrottle",
      sortable: false,
    },
    {
      text: "BYPASS THROTTLE",
      value: "bypassThrottle",
      sortable: false,
      align: "center",
      width: "150px",
      managerOnly: true,
    },
    { text: "", value: "delete", sortable: false, align: "center" },
  ];

  @Prop({
    default: false,
  })
  isManager!: boolean;

  mounted() {
    if (!this.isManager)
      this.headers = this.headers.filter((x) => !x.managerOnly);
  }

  addAsset() {
    this.localPortfolio.unshift({
      _id: (Math.random() * 100000000).toString(),
      asset: "",
      percentage: 0,
      buyThrottle: null,
      sellThrottle: null,
      edit: true,
    } as any);
  }

  deleteAsset(item: any) {
    let it = this.localPortfolio.find((x: any) => x.asset === item.asset);
    if (it) this.localPortfolio.splice(this.localPortfolio.indexOf(it), 1);
  }

  getMaxPercentage(current: number): number {
    return (
      +current +
      this.localPortfolio.reduce((accum: any, coin: any) => {
        return accum - +coin.percentage;
      }, 100)
    );
  }

  itemChanged(ev: any, item: any) {
    if (
      +ev.srcElement.value <= this.getMaxPercentage(item.percentage) &&
      +ev.srcElement.value <= 100 &&
      +ev.srcElement.value >= 0
    ) {
      item.percentage = +ev.srcElement.value;
      (document.getElementById(item._id) as any).value = +ev.srcElement.value;
    } else {
      item.percentage = item.percentage + 0;
      (document.getElementById(item._id) as any).value = item.percentage;
    }
  }
}

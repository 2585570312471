import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Accounts from "@/views/Accounts.vue";
import FuturesAccounts from "@/views/FuturesAccounts.vue";
import FuturesAccountView from "@/views/FuturesAccountView.vue";
import AccountViewImproved from "@/views/AccountViewImproved.vue";
import FuturesAccountViewImproved from "@/views/FuturesAccountViewImproved.vue";
import AccountView from "@/views/AccountView.vue";
import Broadcast from "@/views/Broadcast.vue";
import Signals from "@/views/Signals.vue";
import Reports from "@/views/Reports.vue";
import Dashboard from "@/views/Dashboard.vue";
import Bots from "@/views/Bots.vue";
import Settings from "@/views/Settings.vue";
import SettingsReports from "@/views/SettingsReports.vue";
import SpotWalletSettings from "@/views/SpotWalletSettings.vue";
import FuturesAccountSettings from "@/views/FuturesAccountSettings.vue";
import Login from "@/views/Login.vue";
import { AuthStoreModule } from "@/store/modules/auth";
import { AccountsStoreModule } from "@/store/modules/accounts";
import { FuturesAccountsStoreModule } from "@/store/modules/futuresAccounts";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/accounts",
    beforeEnter: (to, from, next) => {
      if (AuthStoreModule.isAuthenticated) next();
      else next({ name: "Login" });
    },
    children: [
      {
        component: Accounts,
        name: "Accounts",
        path: "/accounts",
      },
      {
        component: FuturesAccounts,
        name: "FuturesAccounts",
        path: "/futures-accounts",
      },
      {
        component: FuturesAccountViewImproved,
        name: "FuturesAccountView",
        path: "/futures-accounts/:id",
        beforeEnter: async (to, from, next) => {
          const res = await FuturesAccountsStoreModule.getFuturesAccountById(to.params["id"]);
          if (res) next();
          else next("/");
        },
      },
      {
        component: FuturesAccountSettings,
        name: "FuturesAccountSettings",
        path: "/futures-accounts/:id/settings",
        beforeEnter: async (to, from, next) => {
          const res = await FuturesAccountsStoreModule.getFuturesAccountById(to.params["id"]);
          if (res) next();
          else next("/");
        },
      },
      // TODO Refactor next 2 routes
      {
        component: AccountViewImproved,
        name: "AccountView",
        path: "/accounts/:id",
        beforeEnter: async (to, from, next) => {
          const res = await AccountsStoreModule.getAccountById(to.params["id"]);
          if (res) next();
          else next("/");
        },
      },
      {
        component: SpotWalletSettings,
        name: "SpotWalletSettings",
        path: "/accounts/:id/settings",
        beforeEnter: async (to, from, next) => {
          const res = await AccountsStoreModule.getAccountById(to.params["id"]);
          if (res) next();
          else next("/");
        },
      },
      {
        component: Broadcast,
        name: "Broadcast",
        path: "/broadcast",
        beforeEnter: (to, from, next) => {
          const res = AuthStoreModule.isManager;
          if (res) next();
          else next("/");
        },
      },
      {
        component: Signals,
        name: "Signals",
        path: "/signals",
        beforeEnter: (to, from, next) => {
          const res = AuthStoreModule.isManager;
          if (res) next();
          else next("/");
        },
      },
      {
        component: Bots,
        name: "Bots",
        path: "/Bots",
        beforeEnter: (to, from, next) => {
          const res = AuthStoreModule.isManager;
          if (res) next();
          else next("/");
        },
      },
      {
        component: Reports,
        name: "Reports",
        path: "/Reports",
        beforeEnter: (to, from, next) => {
          const res = AuthStoreModule.isManager;
          if (res) next();
          else next("/");
        },
      },
      {
        component: Dashboard,
        name: "Dashboard",
        path: "/dashboard",
      },
      {
        component: Settings,
        name: "Settings",
        path: "/settings",
        children: [
          {
            component: SettingsReports,
            name: "SettingsReports",
            path: "reports",
          }
        ]
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (!AuthStoreModule.isAuthenticated) next();
      else next({ name: "Home" });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

import {
  Module,
  VuexModule,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import axios from "axios";
import { SharedStoreModule } from "./shared";

export interface UpdateSignal {
  caller: { username: string, name: string, id: string }
  createdAt: string
  id: string
  value: number
  market: string
  updateType: string
  signalType: string
  targets: []
  updatedAt: string
}
export interface EntrySignal {
  caller: { username: string, name: string, id: string }
  createdAt: string
  id: string
  price: number
  signalDirection: string
  signalType: string
  targets: []
  updatedAt: string
}
export interface CloseOrderSignal {
  caller: { username: string, name: string, id: string }
  market: string
  orderId: string
}
@Module({ dynamic: true, store: store, name: "broadcast" })
class BroadcastModule extends VuexModule {
  exchangeInfo: any = {
    symbols: []
  };

  entrySignals: EntrySignal[] = [];
  updateSignals: UpdateSignal[] = [];

  @Action({ rawError: true })
  getUpdateSignals() {
    return axios
      .get(`/api/v1/signals/history/update`)
      .then((resp) => {
        this.setUpdateSignals(resp.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }


  @Action({ rawError: true })
  getEntrySignals() {
    return axios
      .get(`/api/v1/signals/history/entry`)
      .then((resp) => {
        this.setEntrySignals(resp.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  getExchangeInfo() {
    return axios
      .get(`https://fapi.binance.com/fapi/v1/exchangeInfo`)
      .then((resp) => {
        this.setExchangeInfo(resp.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Action({ rawError: true })
  emitBroadcastEntry(data: any) {
    return axios
      .post(`/api/v1/signals/entry`, data)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Broadcast saved!",
        });
        return true;
      })
      .catch((err) => {
        SharedStoreModule.setAlert({
          type: "error",
          text: err.response.data.message
        });
        return false;
      });
  }

  @Action({ rawError: true })
  emitBroadcastUpdate(data: any) {
    return axios
      .post(`/api/v1/signals/update`, data)
      .then((resp) => {
        SharedStoreModule.setAlert({
          type: "success",
          text: "Broadcast saved!",
        });
        return true;
      })
      .catch((err) => {
        SharedStoreModule.setAlert({
          type: "error",
          text: err.response.data.message
        });
        return false;
      });
  }

  @Action({ rawError: true })
  emitCloseOrder(data: any) {
    return axios
        .post(`/api/v1/signals/update/closeOrder`, data)
        .then((resp) => {
          SharedStoreModule.setAlert({
            type: "success",
            text: "Order closed!",
          });
          return true;
        })
        .catch((err) => {
          SharedStoreModule.setAlert({
            type: "error",
            text: err.response.data.message
          });
          return false;
        });
  }

  @Mutation
  setExchangeInfo(values: any) {
    this.exchangeInfo = values;
  }

  @Mutation
  setEntrySignals(values: EntrySignal[]) {
    this.entrySignals = values;
  }

  @Mutation
  setUpdateSignals(values: UpdateSignal[]) {
    this.updateSignals = values;
  }
}

export const BroadcastStoreModule = getModule(BroadcastModule);

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/main.scss";
import moment from "moment";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "@/plugins/vuetify";
import Vuelidate from "vuelidate";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { AuthStoreModule } from "./store/modules/auth";
import ImageFallBack from "@/directives/imgFallback";
import VueApexCharts from "vue-apexcharts";

const RECAPTCHA_KEY = "6LfBMEgaAAAAAA740CAtoO51ZiKifKc6zjxROZwK";

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueReCaptcha, { siteKey: RECAPTCHA_KEY });
// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(require("vue-moment"));
Vue.directive("coin-image-fallback", ImageFallBack);
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.filter("timeAgo", (date: string) => {
  return moment(date).fromNow();
});

AuthStoreModule.init().then((x) => {
  new Vue({
    vuetify,
    router,
    store,
    render: (h: any) => h(App),
  }).$mount("#app");
});















































import { AuthStoreModule } from "@/store/modules/auth";
import { Component, PropSync, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ProfileModal extends Vue {
  @PropSync("dialog") visible!: boolean;

  get user() {
    return AuthStoreModule.user;
  }
}

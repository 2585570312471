



































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { handleFormErrors } from "@/helpers/formValidations";
// eslint-disable-next-line no-unused-vars
import { CollectorsReport, ReportsStoreModule } from "@/store/modules/reports";

@Component({
  components: {},
  methods: {
    handleFormErrors,
  },
  validations: {
    form: {
      name: {
        required,
      },
      exchange: {
        required,
      },
      markets: {
        required,
      },
    },
  },
})
export default class CollectorsReportsModal extends Vue {
  @PropSync("dialog") visible!: boolean;

  form: any = {
    name: "",
    exchange: "",
    markets: [],
  };

  isEdit = false;

  @Prop({
    default: () => {
      return {} as CollectorsReport;
    },
  })
  report!: CollectorsReport;

  @Watch("visible")
  handle() {
    if (this.visible) {
      this.initForm();
    } else {
      this.$v.form.$reset();
    }
  }

  get exchanges() {
    return ReportsStoreModule.exchanges;
  }

  get markets() {
    return ReportsStoreModule.exchangesMarkets[this.form.exchange];
  }

  async getExchangeMarkets() {
    let res = await ReportsStoreModule.getExchangeMarkets(this.form.exchange);
    if (res)
      setTimeout(() => {
        this.keepOnlyValidMarketOptions();
      }, 0);
  }

  keepOnlyValidMarketOptions() {
    if (this.form.markets)
      this.form.markets = this.form.markets.filter((x: string) => {
        return this.markets.find((y: string) => y === x);
      });
  }

  initForm() {
    const { name, exchange, markets } = { ...this.report };
    this.form = {
      name,
      exchange,
      markets,
    };
    if (this.form.exchange)
      ReportsStoreModule.getExchangeMarkets(this.form.exchange);
    this.isEdit = !!this.report.id;
    console.log(this.isEdit);
  }

  mounted() {}

  async save() {
    let response;
    if (this.isEdit) {
      response = await ReportsStoreModule.putReportsCollector({
        id: this.report.id,
        ...this.form,
      });
      console.log(response);
    } else {
      response = await ReportsStoreModule.postReportsCollector({
        ...this.form,
      });
      console.log(response);
    }
    if (response) {
      ReportsStoreModule.getReportsCollectors();
      this.visible = false;
    }
  }
}

import {
  Module,
  VuexModule,
  getModule,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";

@Module({ dynamic: true, store: store, name: "menu" })
class Menu extends VuexModule {
  public drawer = false;

  @Mutation
  setDrawer(value: boolean) {
    this.drawer = value;
  }
}

export const MenuStoreModule = getModule(Menu);

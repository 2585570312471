


































































































import { Component, Vue } from "vue-property-decorator";
import { MenuStoreModule } from "@/store/modules/menu";
import { AuthStoreModule } from "@/store/modules/auth";

@Component
export default class Sidebar extends Vue {
  get drawer() {
    return MenuStoreModule.drawer;
  }

  set drawer(value: boolean) {
    MenuStoreModule.setDrawer(value);
  }

  items = [
    { title: "Spot Accounts", icon: "mdi-text-box-outline", to: "/accounts" },
    {
      title: "Futures Accounts",
      icon: "mdi-text-box-plus-outline",
      to: "/futures-accounts",
    },
    {
      title: "Broadcast",
      icon: "mdi-broadcast",
      to: "/broadcast",
      managersOnly: true,
    },
    {
      title: "Signals",
      icon: "mdi-signal-variant",
      to: "/signals",
      managersOnly: true,
    },
    {
      title: "Reports",
      icon: "mdi-table",
      to: "/reports",
      managersOnly: true,
    },
    {
      title: "Bots",
      icon: "mdi-robot",
      to: "/bots",
      managersOnly: true,
    },
    // { title: "Settings", icon: "mdi-cog-outline", to: "/settings" },
  ];
  mini = false;

  isViewActive(item: any) {
    if (item.managersOnly) return AuthStoreModule.isManager;
    return true;
  }

  lockScreen() {
    AuthStoreModule.setLockScreen(true);
  }
}

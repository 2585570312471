


















import { Component, Prop, Vue } from "vue-property-decorator";
import { Account } from "@/store/modules/accounts";

@Component({
  components: {},
})
export default class ManagePortfoliosWidget extends Vue {
  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;

  mounted() {}
}

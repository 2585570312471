import {
  Module,
  VuexModule,
  getModule,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";

export interface Alert {
  id?: string;
  type: string;
  text: string;
  value?: boolean;
}

@Module({ dynamic: true, store: store, name: "shared" })
class Shared extends VuexModule {
  public alerts: Alert[] = [];

  get isThemeLight() {
    let theme = localStorage.getItem('theme')
    if (theme)
      return theme === 'light'
    return true
  }

  @Mutation
  setAlert(alert: Alert) {
    const currArray = JSON.parse(JSON.stringify(this.alerts));
    alert.value = false;
    alert.id = Math.random()
      .toString(36)
      .slice(2);
    currArray.push(alert);
    this.alerts = currArray;
  }

  @Mutation
  clearAlert(id: string) {
    let alert = this.alerts.find((al) => al.id === id);
    if (alert) {
      alert.value = false;
    }
  }

  @Mutation
  showAlert(index: number) {
    this.alerts[index].value = true;
  }
}

export const SharedStoreModule = getModule(Shared);










import { Component, Vue, Watch } from "vue-property-decorator";
import interceptorsSetup from "./helpers/httpInterceptors";
import store from "@/store";
import Alerts from "@/components/Alerts.vue";
import LockScreen from "@/components/LockScreen.vue";
import { SharedStoreModule } from "./store/modules/shared";
import { FuturesAccountsStoreModule } from "./store/modules/futuresAccounts";
import { AccountsStoreModule } from "./store/modules/accounts";

interceptorsSetup(store.commit);

@Component({
  components: { Alerts, LockScreen },
})
export default class App extends Vue {
  mounted() {
    const isThemeLight = SharedStoreModule.isThemeLight;
    this.$vuetify.theme.dark = !isThemeLight;
  }

  get selectedFuturesAccountName() {
    let acc = FuturesAccountsStoreModule.accountsMap[this.accountId];
    if (acc) return acc.name;
    return "";
  }

  get selectedSpotAccountName() {
    let acc = AccountsStoreModule.accountsMap[this.accountId];
    if (acc) return acc.name;
    return "";
  }

  get accountId() {
    return this.$route.params["id"];
  }

  @Watch("$route", { deep: true })
  routeChanged(newVal: any) {
    if (newVal && newVal.name === "FuturesAccountView") {
      document.title = `algometrics.ai | ${this.selectedFuturesAccountName}`;
    } else if (newVal && newVal.name === "AccountView") {
      document.title = `algometrics.ai | ${this.selectedSpotAccountName}`;
    } else {
      document.title = "algometrics.ai";
    }
    console.log(newVal);
  }
}



















































import { Component, Prop, Vue } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { ChartsStoreModule } from "@/store/modules/charts";
import { Account, AccountsStoreModule } from "@/store/modules/accounts";
import moment from "moment";
import {
  // eslint-disable-next-line no-unused-vars
  FuturesAccount,
  FuturesAccountsStoreModule,
} from "@/store/modules/futuresAccounts";
import { AuthStoreModule } from "@/store/modules/auth";

@Component({
  components: {},
})
export default class WalletBalanceWidget extends Vue {
  @Prop({
    default: () => {
      return false;
    },
  })
  isFutures!: boolean;
  @Prop({
    default: () => {
      return new Account({});
    },
  })
  account!: Account;

  @Prop({
    default: () => {
      false;
    },
  })
  onlyUsd!: boolean;

  @Prop({
    default: () => {
      return true;
    },
  })
  showSwitch!: boolean;

  error = false;

  sw = true;
  usdValue = 0;

  daysBackIndex = 0;
  daysBack = 7;

  intervals = [
    {
      value: 7,
      label: "7D",
    },
    {
      value: 30,
      label: "1M",
    },
    {
      value: 90,
      label: "3M",
    },
    {
      value: 365,
      label: "1Y",
    },
    {
      value: -1,
      label: "YTD",
    },
    {
      value: 0,
      label: "ALL",
    },
  ];

  selectedSeriesIndex = 0;

  walletBalanceSeries: any = [
    {
      name: "BTC",
      data: [],
    },
    {
      name: "USD",
      data: [],
    },
  ];

  chartOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      width: "100%",
    },

    colors: ["#f2a900"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        opacityFrom: 0.8,
        opacityTo: 0.5,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    legend: {
      show: false,
    },
  };

  series = [
    {
      name: "Series 1",
      data: [45, 52, 38, 45, 19, 23, 2],
    },
  ];

  get selectedSeries() {
    return [this.walletBalanceSeries[this.selectedSeriesIndex]];
  }

  changeDaysBack(interval: any, index: number) {
    this.daysBackIndex = index;
    this.daysBack = interval.value;
    if (interval.label === "YTD")
      this.daysBack = moment().diff(moment().dayOfYear(1), "days");
    if (interval.label === "ALL")
      this.daysBack = moment().diff(
        moment(AuthStoreModule.user.createdAt),
        "days"
      );
    this.initWalletBalance();
  }

  mounted() {
    if (this.onlyUsd) {
      this.changed(false);
    }
    this.initWalletBalance();
  }

  initWalletBalance() {
    let walletBalanceSeries: any = [
      {
        name: "BTC",
        data: [],
      },
      {
        name: "USD",
        data: [],
      },
    ];
    if (this.isFutures) {
      FuturesAccountsStoreModule.getFuturesBTCMetrics({
        accountId: this.account.id!,
        daysBack: this.daysBack,
      })
        .then((resp) => {
          resp.map((x: any, index: number) => {
            walletBalanceSeries[0].data.push({
              y: x.walletBalance,
              x: moment(x.createdAt).format("MM/DD/YYYY - HH:mm"),
            });
            walletBalanceSeries[1].data.push({
              y: x.walletBalance,
              x: moment(x.createdAt).format("MM/DD/YYYY - HH:mm"),
            });
            if (index === resp.length - 1)
              this.usdValue = (this
                .account as FuturesAccount).walletStatus!.walletBalance;
          });
          this.walletBalanceSeries = walletBalanceSeries;
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        });
    } else {
      AccountsStoreModule.getBTCMetrics({
        accountId: this.account.id!,
        daysBack: this.daysBack,
      }).then((resp) => {
        resp.map((x: any, index: number) => {
          walletBalanceSeries[0].data.push({
            y: x.walletBalance,
            x: moment(x.createdAt).format("MM/DD/YYYY - HH:mm"),
          });
          walletBalanceSeries[1].data.push({
            y: x.dollarBalance,
            x: moment(x.createdAt).format("MM/DD/YYYY - HH:mm"),
          });
          if (index === resp.length - 1) this.usdValue = x.dollarBalance;
        });
        this.walletBalanceSeries = walletBalanceSeries;
      });
    }
  }

  changed(value: boolean) {
    let chart: any = this.$refs["chart"];
    if (value) {
      this.selectedSeriesIndex = 0;
      chart.updateOptions({
        colors: ["#f2a900"],
      });
    } else {
      this.selectedSeriesIndex = 1;
      chart.updateOptions({
        colors: ["#26947d"],
      });
    }
  }
}


































import { Component, Vue } from "vue-property-decorator";
import { BotsStoreModule } from "@/store/modules/bots";

@Component({
  components: {},
})
export default class Bots extends Vue {
  headers = [
    { text: "NAME", value: "name" },
    { text: "Last Known Address", value: "lastKnownAddress" },
    { text: "Last Snapshot At", value: "lastSnapshotAt" },
    { text: "Strategy", value: "strategy" },
  ];

  get bots() {
    return BotsStoreModule.bots;
  }

  mounted() {
    BotsStoreModule.getBots();
  }
}












import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AssetsRatioChart extends Vue {
  chartOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
      },
      animations: {
        enabled: false,
        animateGradually: {
          enabled: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1,
    },
    title: {
      text: "Portfolio Dominance",
      align: "left",
    },
    xaxis: {
      type: "datetime",
    },
    animations: {
      enabled: false,
      animateGradually: {
        enabled: false,
      },
    },
    legend: {
      showForSingleSeries: true,
    },
    yaxis: [
      {
        title: {
          text: "PERCENTAGE",
        },
      },
    ],
    tooltip: {
      x: {
        show: true,
        format: "dd MMMM yyyy - HH:ss",
        formatter: undefined,
      },
    },
  };

  @Prop({
    default: () => {
      return [];
    },
  })
  series!: [];
}

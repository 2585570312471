





























































































import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { handleFormErrors } from "@/helpers/formValidations";
// eslint-disable-next-line no-unused-vars
import { ReportsStoreModule } from "@/store/modules/reports";
import moment from "moment";

@Component({
  components: {},
  methods: {
    handleFormErrors,
  },
  validations: {
    form: {
      name: {
        required,
      },
      date: {
        required,
      },
      exchange: {
        required,
      },
      markets: {
        required,
      },
    },
  },
})
export default class ReportModal extends Vue {
  @PropSync("dialog") visible!: boolean;

  menu = false;
  form = {
    name: "",
    exchange: "",
    markets: "",
    date: moment().toISOString(),
  };

  isEdit = false;

  @Watch("visible")
  handle() {
    if (this.visible) {
      this.initForm();
    } else {
      this.$v.form.$reset();
    }
  }

  get getMomentFormat() {
    return moment(this.form.date).format("MM/DD/YYYY");
  }

  get exchanges() {
    return ReportsStoreModule.exchanges;
  }

  get markets() {
    if (this.form.exchange)
      return ReportsStoreModule.collectorsReports.filter(
        (x) => x.exchange === this.form.exchange
      );
    return [];
  }

  initForm() {
    this.form = {
      name: "",
      exchange: "",
      markets: "",
      date: moment().toISOString(),
    };
  }

  mounted() {}

  async save() {
    if (!this.$v.form.$invalid) {
      let response = await ReportsStoreModule.generateReport({
        name: this.form.name,
        startDate: new Date(this.form.date).getTime(),
        list: this.markets.find((x) => x.id === this.form.markets),
      });
      if (response) {
        ReportsStoreModule.getReports();
        this.visible = false;
      }
    }
  }
}

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    light: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#26947d",
          lighten1: "#dffef7",
        },
        background: {
          base: "#ffffff",
          darken1: "#F2F6FA",
          darken2: "#FFFFFF",
        },
        secondary: "#3c3b54",
        accent: "#a3a0fb",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4ac04e",
        warning: "#FFC107",
      },
      dark: {
        primary: {
          base: "#26947d",
          lighten1: "#0f4036",
        },
        background: {
          base: "#121719",
          darken1: "#181c1d",
          darken2: "#2D333D",
        },
        secondary: "#3c3b54",
        accent: "#a3a0fb",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4ac04e",
        warning: "#FFC107",
      },
    },
  },
};

export default new Vuetify(opts);

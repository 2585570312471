








































































import { Component, Vue, PropSync, Watch, Prop } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { handleFormErrors } from "@/helpers/formValidations";

@Component({
  components: {},
  methods: { handleFormErrors },
  validations: {
    form: {
      name: {
        required,
      },
      apiKey: {
        required,
      },
      secret: {
        required,
      },
      balanceInterval: {
        required,
      },
      buyThrottle: {
        required,
      },
      sellThrottle: {
        required,
      },
    },
  },
})
export default class SettingsStep extends Vue {
  @PropSync("valid") validCloned!: boolean;

  @Prop({ default: 2 })
  step!: boolean;

  @Watch("step")
  newStep(newval: number, oldval: number) {
    if (oldval == 2) this.$v.form.$touch();
  }

  exchanges = [
    {
      label: "Binance",
      key: "binance",
    },
  ];

  form = {
    name: "binance",
    api: "",
    secret: "",
    balanceInterval: 2,
    buyThrottle: 33,
    sellThrottle: 33,
  };

  mounted() {
    this.$root.$on("reset-create-account-steps", () => {
      this.form = {
        name: "binance",
        api: "",
        secret: "",
        balanceInterval: 2,
        buyThrottle: 33,
        sellThrottle: 33,
      };
      setTimeout(() => {
        this.$v.form.$reset();
      }, 0);
    });
  }

  @Watch("form", { deep: true })
  formChanged() {
    this.validCloned = !this.$v.form.$invalid;
  }
}









import { Component, Prop, Vue } from "vue-property-decorator";
import { FuturesAccount } from "@/store/modules/futuresAccounts";

@Component({
  components: {},
})
export default class PortfolioFeedWidget extends Vue {
  @Prop({
    default: () => {
      return new FuturesAccount({});
    },
  })
  account!: FuturesAccount;
}

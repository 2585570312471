


















































































































































































































































import { Component, Emit, PropSync, Vue } from "vue-property-decorator";
import {
  required,
  requiredIf,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import { handleFormErrors } from "@/helpers/formValidations";
import Slider from "@/components/shared/Slider.vue";
import { BroadcastStoreModule } from "@/store/modules/broadcast";
import { FuturesAccountsStoreModule } from "@/store/modules/futuresAccounts";
import { AuthStoreModule } from "@/store/modules/auth";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

@Component({
  components: { Slider, ConfirmModal },
  methods: {
    handleFormErrors,
  },
  validations() {
    return {
      form: {
        strategyType: { required },
        signalType: {
          required,
        },
        signalDirection: {
          required: requiredIf(() => {
            return (this as any).form.signalType === "entry";
          }),
        },
        market: { required },
        orderType: {
          required: requiredIf(() => {
            return (this as any).form.signalType === "entry";
          }),
        },
        price: {},
        updateType: {
          required: requiredIf(() => {
            return (this as any).form.signalType === "update";
          }),
        },
        value: {
          required: requiredIf(() => {
            return (this as any).form.signalType === "update";
          }),
        },
        leverage: { minValue: minValue(0), maxValue: maxValue(20) },
        percentage: { minValue: minValue(0), maxValue: maxValue(100) },
      },
    };
  },
})
export default class EmitBroadcastModal extends Vue {
  showConfirmModal = false;
  @PropSync("dialog") visible!: boolean;
  selectedMarket: any = {};
  orderTypes = [
    "LIMIT",
    "MARKET",
    "STOP",
    "STOP_MARKET",
    "STOP_LOSS",
    "TAKE_PROFIT",
    "TAKE_PROFIT_MARKET",
    "TRAILING_STOP_MARKET",
  ];
  directionOptions = [
    {
      text: "Short",
      value: "short",
    },
    {
      text: "Long",
      value: "long",
    },
  ];

  broadcastOptions = [
    {
      text: "Position Entry",
      value: "entry",
    },
    {
      text: "Position Update",
      value: "update",
    },
  ];

  form = {
    strategyType: "dinu",
    market: "",
    signalType: "entry",
    signalDirection: "long",
    updateType: "",
    orderType: "MARKET",
    price: 0,
    value: 0,
    leverage: 0,
    percentage: 0,
    bots: [] as string[],
  };

  get isPartiallySelected() {
    return (
      this.form.bots.length !==
        this.futureAccountsOwned.length + this.futureAccountsManaged.length &&
      this.form.bots.length !== 0
    );
  }

  get isAllSelected() {
    return (
      this.form.bots.length ===
      this.futureAccountsOwned.length + this.futureAccountsManaged.length
    );
  }

  set isAllSelected(value: boolean) {
    if (value) {
      this.form.bots = this.futureAccountsOwned
        .map((x) => x.bot!.id)
        .concat(this.futureAccountsManaged.map((x) => x.bot!.id)) as string[];
    }
    if (!value) this.form.bots = [];
  }

  get exchangeInfo() {
    return BroadcastStoreModule.exchangeInfo.symbols;
  }

  get futureAccountsOwned() {
    return FuturesAccountsStoreModule.accounts.own
      ? FuturesAccountsStoreModule.accounts.own.filter(
          (x) => x.strategy === "dinu"
        )
      : [];
  }

  get futureAccountsManaged() {
    return FuturesAccountsStoreModule.accounts.managed.filter(
      (x) =>
        x.strategy === "dinu" &&
        this.futureAccountsOwned.find((acc) => acc.id === x.id) === undefined
    );
  }

  mounted() {
    BroadcastStoreModule.getExchangeInfo();
    FuturesAccountsStoreModule.getFuturesAccounts(AuthStoreModule.user.id);
  }

  decreaseLeverage() {
    this.form.percentage =
      this.form.percentage > Math.floor(this.form.percentage)
        ? Math.floor(this.form.percentage)
        : Math.floor(this.form.percentage) - 1 >= 0
        ? Math.floor(this.form.percentage) - 1
        : 0;
  }

  increaseLeverage() {
    this.form.percentage =
      Math.floor(this.form.percentage) + 1 > 100
        ? 100
        : Math.floor(this.form.percentage) + 1;
  }

  percentageChanged(value: number) {
    this.form.percentage = value;
  }

  text(item: any) {
    if (item.contractType === "CURRENT_QUARTER") {
      return `${item.baseAsset}/${item.marginAsset} Futures`;
    }
    return `${item.baseAsset}/${item.marginAsset}`;
  }

  selectMarket(val: string) {
    this.selectedMarket = this.exchangeInfo.find((x: any) => x.symbol == val);
  }

  changeType(event: any) {
    this.form.signalType = event;
  }

  toggleConfirmModal(value: boolean) {
    this.showConfirmModal = value;
    if (value)
      this.$root.$on("confirm-ok", () => {
        this.save();
        this.$root.$off("confirm-ok");
        this.showConfirmModal = false;
      });
    else this.$root.$off("confirm-ok");
  }

  subtext() {
    return this.form.bots
      .map((x) => {
        let acc;
        acc = this.futureAccountsOwned.find((y) => y.bot!.id === x);
        if (!acc) acc = this.futureAccountsManaged.find((y) => y.bot!.id === x);
        if (acc)
          return `${acc.name} - ${((this.form.percentage / 10) * acc.walletStatus!.availableBalance).toFixed(2)}USDT`;
        return "";
      })
      .join("\n");
  }

  @Emit("emit")
  save() {
    if (this.form.signalType === "entry")
      return {
        strategyType: this.form.strategyType,
        signalType: this.form.signalType,
        orderType: this.form.orderType,
        market: this.form.market,
        signalDirection: this.form.signalDirection,
        price: this.form.price,
        leverage: this.form.percentage / 10,
        bots: this.form.bots,
      };
    else
      return {
        strategyType: this.form.strategyType,
        signalType: this.form.signalType,
        market: this.form.market,
        updateType: this.form.updateType,
        value: this.form.value,
        bots: this.form.bots,
      };
  }
}

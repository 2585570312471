















import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Slider extends Vue {
  @PropSync("selectedValue") value!: string;
  @Prop({
    default: () => {
      return [];
    },
  })
  values!: any[];

  @Prop({
    default: () => {
      return [];
    },
  })
  colors!: string[];

  mounted() {}
}

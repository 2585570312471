import {
  Module,
  VuexModule,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import axios from "axios";
import { SharedStoreModule } from "./shared";

@Module({ dynamic: true, store: store, name: "signals" })
class SignalsModule extends VuexModule {
  predictumSignals: any[] = [];

  @Action({ rawError: true })
  getPredictumSignals() {
    return axios
      .get(`https://x100.xyz/api/v1/predictum/history`, {
        headers: { "Access-Control-Allow-Origin": "*" }
      })
      .then((resp) => {
        this.setPredictumSignals(resp.data.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  @Mutation
  setPredictumSignals(values: any[]) {
    this.predictumSignals = values.map(x => {
      return {
        id: x[0],
        botId: x[1],
        exchange: x[2],
        symbol: x[3].replace("PERP", ""),
        short: x[4],
        long: x[5],
        direction: x[6],
        price: x[7],
        stop_loss: x[8],
        stop_loss_multiplier: x[9],
        date: x[10]
      }
    })
  }
}

export const SignalsStoreModule = getModule(SignalsModule);

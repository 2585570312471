var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"widget-card settings-spot-wallet-table"},[_c('v-data-table',{staticClass:"default-table-widget",attrs:{"headers":_vm.headers,"items":_vm.account.portfolio,"custom-sort":_vm.customSort,"items-per-page":-1,"show-expand":"","item-key":"asset","single-expand":true,"expanded":_vm.expanded,"sort-by":'now'},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header.percentage",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"percentage-header"},[_vm._v(" "+_vm._s(header.text)+" "),_c('span',{staticClass:"total-value",class:{ 'full-set': _vm.portfolioPercentageSet == 100 }},[_vm._v(" "+_vm._s(_vm.portfolioPercentageSet.toFixed(2))+"% Allocated ")])])]}},{key:"item.asset",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"logo"},[_c('img',{directives:[{name:"coin-image-fallback",rawName:"v-coin-image-fallback"}],staticClass:"mr-2",attrs:{"src":item.asset.length > 1
              ? ("https://coinicons-api.vercel.app/api/icon/" + (item.asset.toLowerCase()))
              : ''}}),_c('span',[_vm._v(" "+_vm._s(item.asset)+" ")])])]}},{key:"item.allocation",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"allocation"},[_c('v-slider',{attrs:{"color":"#3c3b54","min":"0","max":_vm.getMaxPercentage(item.percentage, item),"step":"0.05","thumb-label":""},model:{value:(item.percentage),callback:function ($$v) {_vm.$set(item, "percentage", $$v)},expression:"item.percentage"}})],1)]}},{key:"item.percentage",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{staticClass:"percentage-input percentage",attrs:{"id":item._id,"rounded":"","filled":"","value":item.percentage,"required":"","type":"number","min":"0","max":_vm.getMaxPercentage(item.percentage, item)},on:{"input":function($event){return _vm.itemChanged($event, item)}}})]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"content-inputs",attrs:{"colspan":headers.length}},[_c('div',[_c('span',{staticClass:"label"},[_vm._v("Buy Throttle")]),_c('v-text-field',{staticClass:"percentage-input",attrs:{"type":"number","rounded":"","filled":"","error-messages":item.buyThrottle > 100 || item.buyThrottle < 0
                ? 'Min 0 / Max 100'
                : ''},model:{value:(item.buyThrottle),callback:function ($$v) {_vm.$set(item, "buyThrottle", $$v)},expression:"item.buyThrottle"}}),_c('span',{staticClass:"label"},[_vm._v("Sell Throttle")]),_c('v-text-field',{staticClass:"percentage-input",attrs:{"type":"number","rounded":"","filled":"","error-messages":item.sellThrottle > 100 || item.sellThrottle < 0
                ? 'Min 0 / Max 100'
                : ''},model:{value:(item.sellThrottle),callback:function ($$v) {_vm.$set(item, "sellThrottle", $$v)},expression:"item.sellThrottle"}}),_c('v-checkbox',{attrs:{"label":"Bypass Throttle"},model:{value:(item.bypassThrottle),callback:function ($$v) {_vm.$set(item, "bypassThrottle", $$v)},expression:"item.bypassThrottle"}})],1)])]}},{key:"item.delete",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"delete"},[_c('v-btn',{attrs:{"text":"","icon":"","rounded":"","color":"red"},on:{"click":function($event){return _vm.deleteAsset(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('v-btn',{attrs:{"color":"primary","text":"","elevation":"0"},on:{"click":function($event){return _vm.toggleShowAddAssetModal(true)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Asset ")],1),_c('AddAssetModal',{attrs:{"dialog":_vm.showAddAssetModal},on:{"update:dialog":function($event){return _vm.toggleShowAddAssetModal(false)},"update":_vm.addAsset}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
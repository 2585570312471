var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"assets-table elevation-1 default-table-style",attrs:{"headers":_vm.headers,"items":_vm.localPortfolio,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.asset",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"logo"},[_c('img',{directives:[{name:"coin-image-fallback",rawName:"v-coin-image-fallback"}],staticClass:"mr-2",attrs:{"src":item.asset
            ? 'https://cryptoicons.org/api/icon/' +
              item.asset.toLowerCase() +
              '/50'
            : ''}}),(!item.edit)?_c('span',[_vm._v(" "+_vm._s(item.asset)+" ")]):_c('v-text-field',{staticClass:"mr-1",attrs:{"rounded":"","filled":"","error-messages":item.asset.trim() === '' ? 'Required' : '',"required":""},model:{value:(item.asset),callback:function ($$v) {_vm.$set(item, "asset", $$v)},expression:"item.asset"}})],1)]}},{key:"item.bypassThrottle",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.bypassThrottle),callback:function ($$v) {_vm.$set(item, "bypassThrottle", $$v)},expression:"item.bypassThrottle"}})]}},{key:"item.percentage",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"percentage"},[_c('input',{staticClass:"custom-input-number",attrs:{"type":"number","min":"0","max":"100","id":item._id},domProps:{"value":item.percentage},on:{"input":function($event){return _vm.itemChanged($event, item)}}}),_c('v-slider',{attrs:{"color":"#3c3b54","min":"0","max":_vm.getMaxPercentage(item.percentage, item),"step":"0.05","thumb-label":""},model:{value:(item.percentage),callback:function ($$v) {_vm.$set(item, "percentage", $$v)},expression:"item.percentage"}})],1)]}},{key:"item.buyThrottle",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"buyThrottle"},[_c('v-text-field',{attrs:{"type":"number","rounded":"","filled":"","error-messages":item.buyThrottle > 100 || item.buyThrottle < 0
            ? 'Min 0 / Max 100'
            : ''},model:{value:(item.buyThrottle),callback:function ($$v) {_vm.$set(item, "buyThrottle", $$v)},expression:"item.buyThrottle"}})],1)]}},{key:"item.sellThrottle",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"sellThrottle"},[_c('v-text-field',{attrs:{"type":"number","rounded":"","filled":"","error-messages":item.sellThrottle > 100 || item.sellThrottle < 0
            ? 'Min 0 / Max 100'
            : ''},model:{value:(item.sellThrottle),callback:function ($$v) {_vm.$set(item, "sellThrottle", $$v)},expression:"item.sellThrottle"}})],1)]}},{key:"header.delete",fn:function(ref){return [_c('v-btn',{attrs:{"rounded":"","color":"secondary lighten-2","elevation":"0"},on:{"click":function($event){return _vm.addAsset()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Asset ")],1)]}},{key:"item.delete",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"delete"},[_c('v-btn',{attrs:{"text":"","icon":"","rounded":"","color":"red"},on:{"click":function($event){return _vm.deleteAsset(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }